
/*@font-face {
    font-family: 'Gilroy';
    src: url('gilroy-thinitalic-webfont.woff2') format('woff2'),
         url('gilroy-thinitalic-webfont.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: optional;
}   */




@font-face {
    font-family: 'Gilroy';
    src: url('gilroy-medium-webfont.woff2') format('woff2'),
         url('gilroy-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}




@font-face {
    font-family: 'Gilroy';
    src: url('gilroy-semibold-webfont.woff2') format('woff2'),
         url('gilroy-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}   




@font-face {
    font-family: 'Gilroy';
    src: url('gilroy-light-webfont.woff2') format('woff2'),
         url('gilroy-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}




@font-face {
    font-family: 'Gilroy';
    src: url('gilroy-bold-webfont.woff2') format('woff2'),
         url('gilroy-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}




@font-face {
    font-family: 'Gilroy';
    src: url('gilroy-regular-webfont.woff2') format('woff2'),
         url('gilroy-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}