@import  "Variables.scss";


html {
  overflow-x: hidden;
  max-width: 100vw;
  width: 100vw;
}

body {
  overflow-x: hidden!important;
  max-width: 100%!important;
}

a {
  color: $link;
}

.hidden {
  display: none!important;
}

.App {
  text-align: center;
}

.container {
  width: 100%;
  max-width: 1800px;
  margin: 0 auto;
  /*position:  relative;*/
  box-sizing: border-box;
  padding:0 0.75rem;
}

main.page {
  position:  relative;
  flex-grow: 2;
  /*padding-bottom: 1.5rem;*/
}


h1 {
  color: $title;
}

h1/*,h2,h3,h4*/ {
  font-weight: 500;
}

.center {
  text-align: center;
}


button {
  white-space: nowrap
}

button svg {
  user-select: none;
  pointer-events: none;
}


img {
  position: relative;
}

picture {
  display: block;
}

img::before {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  transform: translate(-50%, -50%);
  background: transparent;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 1rem 0;
}

.image-placeholder-span {
  display: block
}

/*#form_login,
#form_registration {
  padding: 0.75rem 1.5rem;
  max-width: 600px;
  margin: 0 auto;
}*/

.form_block {
  padding: 0.75rem 1.5rem;
  max-width: 600px;
  margin: 0 auto;
  max-width: 100vw!important;
  box-sizing: border-box;
}

.form-container {
  
  line-height: 1.5;
}

.form-element {
  margin-bottom: 0.75rem;
}


.box-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}





section.dark {
  background: $title;
  color: #fff;
  padding: 3rem 0;
  overflow: hidden;
}

.big {
  font-size: 1.25rem;
}

.c2 >.row{
  display: flex;
  flex-direction: row;
  margin: 0 -1rem;
  flex-wrap: wrap
} 

.c2 >.row > .column {
  width: 50%;
  padding: 0 1rem;
  box-sizing: border-box;
}

.relative {
  position: relative;
}

.floater {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 2rem;
  font-weight: 800;
  color: #ddd;
  transform: translate(-50%, -50%);
  text-align: center;
}


.message_error {
  background: #f99;
  padding: 0.25rem 0.5rem;
  font-weight: 500;
  border-radius: 0.125rem;
}


#content_wrapper {
  min-height: 100vh;
  pading-top: 3.75rem;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  flex-direction:column;
  justify-content:space-between
}


.bs {
  border-radius: 1rem;
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.listing_wrapper, .showroom_wrapper {
  padding: 0.75rem;

  position: relative;
  cursor: pointer;
}

.news_wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 3rem;
}

article section {
  background: #fff;
  padding: 1.5rem;
  border-radius: 0.25rem;
  margin-bottom: 3rem;
}

h2 {
  font-weight: 500;
  margin-top: 2.5rem;
}



section#listing_main.bs {
  overflow: visible;
}



.form-container.error input{
  border-color: #c00;
}


.simpleA {
  text-decoration: none;
}

.darkHr {
  background: $footer;
  border-color: $footer;
}

button {
  display: block;
  padding: 0;
  margin: 0;
  width: 3.5rem;
  height: 2rem;
  background: #fff;
  border: none;
  text-align: center;
}


button.black, a.btn.black {
  background: #000;
  width: auto;
  padding: 0 2rem;
  border-radius: 0.9rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  line-height: 3rem;
  height: 2.75rem;
  font-size: 1.25rem;
  text-decoration: none;
  margin-top: 1.5rem;
}

button.black.small {
  font-size: 1rem;
  padding: 0 1.5rem;
  height: 2.25rem;
  line-height: 2.25rem;
}

button.black.download svg{
  width: 1.25rem;
  height: 1.25rem;
  vertical-align: -0.125rem;
  margin-right: 0.25rem;
  fill: #fff;
}



button.gray {
  background: #e2e2e2;
  width: auto;
  padding: 0 2rem;
  border-radius: 0.625rem;
  font-weight: 500;
  color: #000;
  font-weight:400;
  display: inline-block;
  line-height: 3rem;
  height: 2.75rem;
  font-size: 1.25rem;
}

button.gray {
  fill: #000;
}

.header_buttons button {
  font-size: 12px;
  line-height: 2;
  color: $gray;
  width: 5rem;
  font-weight: 500;
  height: 3.2rem;
  padding-top: 0.5rem;
}


.button_svg.count {
  fill: #000;
  position: relative;
}

#btn_chat {
  position: relative;
}

#btn_chat .button_svg.count {
  fill: $green!important;
}

.chat_unread {
  position: absolute;
  top: 0;
  left: 50%;
  background: $green;
  width: 100%;
  line-height: 0.8em;
  color: #fff;
  border-radius: 0.15em;
  font-size: 12px;
  width: 1.2em;
  height: 0.8em;
  position: absolute;
  top: 1.25em;
  transform: translateX(-1em);
}




.m0a {
  margin: 0 auto;
  display: block;
}


/*.header_buttons button:last-child {
  width: 2rem;
  margin-left: 1rem
}*/

#btn_user {
  margin-right: -1.3rem
}

.button_svg {
  width: 3rem;
  height: 1.25rem;
  display: block;
  fill: $button;
  margin: 0 auto;
}

button {
  cursor: pointer;
}

button:hover svg{
  fill: $buttonHover;
}

button.down svg{
  //fill: $buttonActive;
  fill: $green;
}






.field_box {
  display: flex;
  /*margin-bottom: 1.5rem;*/
  margin-bottom: 0;
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  /*! box-sizing: border-box; */
}




main .field_box > div.form-element,
main form .form-element {
  width: 25%;
}

.field_box > div.form-element {
  
  box-sizing: border-box;
  padding:0 0.75rem;
  margin-left: 0;
  margin-right:0;
}

.field_box > div:last-child {
  //width: 25%;
  box-sizing: border-box;
  margin-right: 0;
}

form .form-element {

  padding:0 .75rem;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.form-element input {
  width: 100%;
  box-sizing: border-box;
  color: #000;
  font-weight: 500;
}



input:focus {
  outline: 1px solid $green;
  //outline: none;
  //box-shadow: 0 0 2px $green;
}

body .select-innard__control--is-focused {
  outline: 1px solid $green!important;
  //outline: none;
  //box-shadow: 0 0 1px $green;
}

body .select-innard__control {
  box-shadow: none!important;
  
}

body .select-innard__control:hover {
  border: 1px solid $gray;
}



.form-multiple {
  padding: 0 0.75rem;
}

.form-multiple input{
  width: 100%;
  box-sizing: border-box;
  
}

textarea {
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.125rem;
  border: 1px solid #ddd;
  padding: 0.5rem;
  resize: vertical;
}

.form-group-element {
  margin-bottom: 0.5rem;
}

.form-element.dropzone {
  width: 100%!important;
}

.dzu-dropzone {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  padding-bottom: 2rem;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 30vh;
}

.upload_btn {
  position: absolute;
  cursor: pointer;
  width: 100%;
  text-align: center;
  bottom: 0;
  background: #fff;
  height: 2rem;
  line-height: 2rem;
}

.dzu-previewContainer {
  padding: 0.5rem;
  width: 25%;
  border: none
}

.dzu-previewImage {
  width: 100%;
  max-width: 100%;
  height: auto!important;
  max-height: unset;
}

.dzu-previewStatusContainer {
  position: absolute;
  right: 0;
  top: 0;
  background: #eee;
  border-radius: 2rem;
}

.dzu-previewButton {
  margin: 0;
  padding: 0.5rem;
}


.upload_btn {
  height: 100%;
}

.dzu-previewContainer +.upload_btn {
  height: 2rem;
}






.form-element-wide .options {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  
}



.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -0.75rem;
}

.card {
  display: flex;
  width: 25%;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.75rem;
  /*background: #fff;*/
  /*margin: 0.5rem;*/
  position: relative;
  /*border-radius: 0.5rem;*/
  /*box-shadow: 0 0 0.5rem rgba(0,0,0,0.05);*/
}


.call_to_action a.link {
  text-decoration: none;
  color: #299EF3;
  font-weight: 600;
  font-size: 1.5rem;
}



.paragraph_card .card {
  position: relative;
  padding-bottom: 3rem
} 

.page_page .cards {
  margin: 0 -3rem;
}

.page_page .card {
  padding-right: 3rem;
  padding-left: 3rem;
}

.page_page .card h3 {
  margin: 1.5rem 0;
}

.paragraph_card .card .url {
  position: absolute;
  bottom: 0;
}

.paragraph_card .card img {
  border-radius: 0.75rem;
}

.paragraph .call_to_action {
  margin-top: 2rem;
}

.page_page h1{
  font-weight: 600;
  color: #000;
}

.page_page {
  color: #666666;
}

.page_page h2,
.page_page h3 {
  color: #000;
}

.paragraph_black h2,
.paragraph_black h3 {
  color: #fff;
}


.paragraph .text > p:first-child {
  margin-top: 0;
}

.paragraph .text > p:last-child {
  margin-bottom: 0;
}


.print_only {
  display: none;
}

// .align_2l .btn {
//   float: right;
// }

// .align_3l, .align_25l, .align_35l .btn {
//   float: right;
// }

.align_25l, .align_35l {
  display: flex;
}

.align_35l .text_wrapper {
  flex: 3;
}

.align_35l .image {
  flex: 2;
}
.align_35l, 
.align_2l,
.align_2l  {
  text-align: right;
}

.playIcon {
  max-width: 5rem;
  max-height: 3.75rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}



.image_black .text_wrapper .url .btn {
  background: #fff;
  color: #000;
}



.button-row, .form-submit.button-row {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}


/**********************/
/****** A1 Button ******/
/**********************/


.a1Button {
  position: relative;
  display: inline-block!important;
  width: auto;
  /*height: auto;*/
  line-height: 2.3;
  height: 2.25rem!important;
  border-radius: 5rem;
  margin-right: 2rem;
  padding-right: 1.25rem;

  .a1Button_inner{
    width: auto;
    display: inline-block;
    line-height: 2.3;
    border-radius: 5rem;
    height: 2.25rem;
    padding: 0 1.25rem 0 1.75rem;
    background: $linkGray;
    color: #fff;
    font-weight: 500;
    min-width: 8rem;
    position: relative;
    z-index: 2;
    text-align: center;
  }

  .a1Button_underlay {
    position: absolute;
    width: 2.5rem;
    height: 100%;
    right: 0;
    background: linear-gradient(to left, #8EC11E, #189000, #212121 70%);
    display: block;
    z-index: 1;
    border-radius: 0 5rem 5rem 0;
    transition: transform 0.25s ease-in-out 0s;
    transform-style: preserve-3d;
  }

  .a1Button_underlay:after {
    content: "";
    display: block;
    background: #fff;
    width: 100%;
    height: 100%;
    border-radius: 5rem;
    right: 0.75rem;
    position: absolute;
    transition: transform 0.25s ease-in-out 0s;
    transform-style: preserve-3d;
  }
}

.a1Button:hover .a1Button_underlay:after{
  transform: translateX(0.1rem);
  //right: 0.6rem!important;
}

.a1Button:hover .a1Button_underlay {
  transform: translateX(-0.25rem);
}

.a1Button.minor .a1Button_inner {
  background: #bbb;
  color: #000;
}

.a1Button.short .a1Button_inner{
  min-width: 0; 
}

.a1Button.small .a1Button_inner{
  min-width: 0;
  height: 2rem;
  line-height: 2;
}

.a1Button.small {
  line-height: 2rem;
  height: 2rem!important;
}


.paragraph_black .a1Button .a1Button_inner {
  background: #fff;
  color: $linkGray;
}

.paragraph_black .a1Button .a1Button_underlay:after {
  background: $linkGray;
}

.a1Button_inner svg {
  fill: #fff;
  vertical-align:-0.15rem;
  margin-right: 0.25rem;
}

.paragraph_black .a1Button_inner svg {
 fill: $linkGray;
}

.form-button button.a1Button {
  padding: 0;
  padding-right: 1.25rem;
  border-radius: 5rem;
  font-size: 1rem;
  line-height: 2.3;
  background: transparent;
}

.form-button button.a1Button .a1Button_inner {
  line-height: 2.3rem;
}

.search_box .button button.a1Button {
  padding: 0;
}

.a1Button:hover svg {
  fill: #fff;
}





/**********************/
/****** SHOWROOM ******/
/**********************/



/*#activezone {
  position:  relative;
  overflow: hidden;
}

#clickzones {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  fill:  transparent;
  cursor: pointer;
}

#activezone img {
  width: 100%;
  height: auto;
}

#activezone video {
  position:  absolute;
  width:  100%;
  height:  100%;
  left:  0;
  top:  0;
  transition: opacity 0.5s ease 0s;
  cursor:  pointer;
}

#activezone h2.transparent {
  z-index:  2;
}


#activezone video.playing,
#activezone h2.playing {
  z-index: 15;
}*/







/*#activezone  video {
  outline: unset!important;
  border: none!important;
  width: 100%;
  top: 50%;
  transform: translateY(-50%) scale(1.01);
}

#videoPlaceholder {
  transform: scale(1.01);
  display:  block;
}


#activezone h1 {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  z-index:  100;
}

.shawarma {
  position: absolute;
  width: 50vw;
  top: 50%;
  right: 1rem;
  max-width: 100vw;
  transform: translateY(-50%);
}

.videoBox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  text-align: center;
  width: 100%;
  font-size: 2rem;
  font-weight: bold;
  color: #000;
  cursor: pointer;
  transition: opacity 1s ease 0s;
}


.showroom_logo {
  position: absolute;
  z-index: 100;
  right: 3%;
  width: 20%;
  top: 20%;
}

.showroom_logo.acj {
  width: 12%
}
.showroom_logo.embraer {
  width: 25%;
}




.showroom_button {
  position: absolute;
  z-index: 100;
  right: 3%;
  bottom: 16%;
  background: #000;
  color: #fff;
  font-weight: 600;
  width: auto;
  padding: 1rem 1.5rem;
  display: inline-block;
  height: 3rem!important;
  border-radius: 10rem;
  z-index: 200;
}


.showroom_button::hover {
  background: #555;
  
}

.showroom_button {
  opacity: 0;
  transition: opacity 0.5s ease-in-out 0s;
}

.showroom_button.playing {
  z-index: 600;
  opacity: 1;
}






#showroom_wrapper {
  overflow: hidden;
}*/


.button_back {
  background: transparent;
  color: #000;
  font-size: 1.25rem;
  font-weight: 400;
  top: 0.75rem;
  bottom: auto;
  right: auto;
  left: 0;
  white-space: nowrap;
  width: auto;
  margin-right: 1.5rem;
}

.button_back svg {
  stroke: #000;
  width: 2rem;
  height: 1rem;
  padding-right: 0.5rem;
  vertical-align: -0.15rem;
}

.showroom_card_info {
  //border-bottom: 2px solid #b9b9b9;
  padding-bottom: 0.75rem;
}




.showroom_card {
  margin-bottom: 1rem;
}

.showroom_image .image-placeholder-span {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.card_price {
  white-space: nowrap;
}

#showroom_wrapper h1 {
  margin-bottom: 0.5rem;
  display: none;
}


.showroom_card_title {
  min-height: 4.5rem;
}

.showroom_card_title h3 {
  margin: 0;
  font-size: 500;
}
/*

.showroom_logo {
  transition: opacity 0.5s ease-in-out;
}
.showroom_logo.logo {
  z-index: 100;
  opacity: 1;
}


.showroom_list_wrapper {
  margin-bottom: 3rem;
}




#showroom_logos {
  display: flex;
  margin-top: -1rem;
  position: relative;
  z-index: 600;
}

#showroom_logos .logos_item {
  flex-grow: 2;
  align-self: center;
  text-align: center;
  cursor: pointer;
}

#showroom_logos .logos_item img {
  max-width: 8rem;
  max-height: 2rem;
  transition: transform 0.25s ease-in-out 0s;
}

#showroom_logos .logos_item:hover img,
#showroom_logos .logos_item.active img{
  transform: scale(1.1)
}

#showroom_logos .logos_item.inactive {
  opacity: 0.125;
}


#showroom_logos span.logo_title {
  font-size: 1.25rem;
  display: inline-block;
  padding: 0.75rem 0;
  transition: transform 0.5s ease 0s;
  text-shadow: 0.1rem 0.1rem 0.25rem rgba(0,0,0,0.2)
}

#showroom_logos .logos_item:hover span.logo_title, 
#showroom_logos .logos_item.active span.logo_title{
  font-size: 1.25rem;
  transform: scale(1.25);
}


#activezone {
  margin-top: 2rem;
}


*/

.showroom_card_info .flags{
  position: absolute;
  top: 1.6rem;
  right: 0.75rem;
}

.showroom_card_info .flags .flag {
  white-space: nowrap;
  font-size: 12px;
  border: 1px solid #efeff3;
  padding: 0.125rem 0.25rem;
  border-radius: 0.5rem;
  display: inline-block;
  margin: 0.125rem;
  user-select: none;
  font-weight: 500;
}

#related_showrooms {
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  text-align: right;
  line-height: 2;
  position: relative;
  z-index: 20;
}


#related_showrooms >div{
  padding: 0 1.5rem;
}

#related_showrooms a {
  color: $gray;
  text-decoration: none;
  display: inline-block;
  line-height: 2;
}

#related_showrooms span,
#related_showrooms .current {
  font-weight: 500;
  color: $green;
}







/*NEW!*/




.showroom_intro {
  position: relative;
}

#showroom_guides {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  z-index: 100;
}

#showroom_rods {
  display: block;
}

#showroom_guides path {
  cursor: pointer;
}

#showroom_intro {
  position: relative;
}

.showroom_video_clipbox {
  position: relative;
  overflow: hidden;
}

.showroom_video_clipbox video {
  display: block;
}

.showroom_video_wrapper {
  position: absolute;
  width: 100%;
  /*height: 100%;*/
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out 0s; 
}

.showroom_video_wrapper.paused {
  opacity: 0;
}

.showroom_video_wrapper.playing {
  opacity: 1;
  z-index: 200;
}

#showroom_video {
  position: absolute;
  width: 100%;
  /*height: 100%;*/
  top: 0;
  left: 0;
}


/*.showroom_link {
  text-decoration: none;
  color: #212121;
  font-weight: 400;
  transition: all 0.5s ease-in-out;
}

.showroom_link:hover,
.showroom_link.hover,
.page_showroom.playing .showroom_link.playing {
  font-size: 1.5rem;
  color: #000;
}*/


.showroom_link {
  text-decoration: none;
  color: $linkGray;
  font-weight: 500;
  transform: scale3d(1, 1, 1);
  transition: transform 0.5s ease-in-out;
}

.showroom_link:hover,
.showroom_link.hover,
.page_showroom.playing .showroom_link.playing {
  /*font-size: 1.5rem;*/
  transform: scale3d(1.3, 1.3, 1);
  color: #000;
}



#showroom_links {
  display: flex;
  width: 100vw;
  justify-content: space-evenly;
  line-height: 2rem;
  background: $background;
  padding: 0.75em 0;
}

#showroom_links > a {
  flex-grow: 1;
  text-align: center
}

.showroom_video_link {
  position: absolute;
  right: 1.5rem;
  bottom: 1.5rem;
  /*background: #000;
  padding: 0.5rem 1.5rem;
  font-weight: 500;
  color: #fff;
  border-radius: 1.5rem;
  text-decoration: none;*/
}

.page_showroom.playing .showroom_link {
  color: #bbb;
}


.showroom_video_close {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 3rem;
  display: block;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
}





.showroom_header {
  position: relative;
  transition: filter 0.25s ease-in-out;
}

.showroom_header.showroom_mobile {
  margin-top: 0.75rem
}

.showroom_header::after {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: -1;
  content: "";
  display: block;
  background: #fff;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease-in-out 0s;
}


.showroom_header.loading::after {
  z-index: 100;
  opacity: 0.8;
}

.showroom_header.loading {
  filter: grayscale(100%);
}

.showroom_video_clipbox {
  position: relative;
}

.showroom_video_clipbox video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#hotzone.loading {
  opacity: 0;
}

#hotzone.loaded {
  animation: fade 1s linear;
}

@keyframes fade {  0%, 15% { opacity: 0 }  100% { opacity: 1 }}



.card.showroom_card {
  display: flex;
  width: 25%;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0.75rem;
  cursor: pointer;
  /*margin: 0.5rem;*/
  position: relative;
  /*border-radius: 0.5rem;*/
  /*box-shadow: 0 0 0.5rem rgba(0,0,0,0.05);*/
}

.card.showroom_card .end {
  align-self: center;
}

.showroom_card_title {
  min-height: 5rem;
  display: flex;
  align-items: center;
}

.card.showroom_card .btn_like.like svg{
  fill: $like;
}

.showroom_image {
  position: relative;
  margin-top: 3em;
  margin-left: 0.75rem;
  margin-right: 0.75rem;

  svg, img {
    display: block;
  }
}

.showroom_list_wrapper {
  padding-bottom: 1.5rem;
  background: $background;
}

.showroom_image img {
  /*margin: 0 auto;*/
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}





.card_row {
  display: flex;
  justify-content: space-between;
  padding: 0 0.75em;
  .h {
    font-size: .875rem;
    display: inline-block;
  }
  .v {
    font-size: .875rem;
    display: inline-block;
  }
}

.card_row.card_table {
  position: relative;
  padding-top: 0.5em;
}

.card_row.card_table::before {
  content:"";
  position: absolute;
  display: block;
  height: 1px;
  top: 0;
  width: calc(100% - 1.5rem);
  background: #eee;
}

/*.showroom_card .card_price  {
  background: #fff;
  color: #000;
  border: 2px solid #000;
}*/

.showroom_card .card_price {
  border: none;
  position: relative;
  width: auto;
}




/*.showroom_card .card_price::before {
  content:"";
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  border-radius:50px; 
  padding:2px; 
  background:linear-gradient(45deg, #8ec11e, #000); 
  -webkit-mask: 
     linear-gradient(#fff 0 0) content-box, 
     linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude; 
}*/



.showroom_card .actions {
  position: absolute;
  top: 1.5em;
  left: 1.5em;
  display: flex;
}

.showroom_card .actions button {
  background: #efeff3;
  border-radius: 10em;
  display: block;
  width: 2em;
  height: 2em;
  padding: 0;
  position: relative;
  margin-right: 0.5em
}

.showroom_card .actions button.btn_like svg {
  width: 0.75rem;
  height: 0.75rem;
}

.showroom_card .actions svg {
  width: 1.1em;
  position: absolute;
  fill: $gray;
  height: 1.1em;
  transform: translate(-50%,-50%);
}

.showroom_card .actions .toggled svg {
  fill: $green;
}

.mob {
  display: none;
}

.pc {
  display: block;
}



.mob .showroom_mob_item {
  width: 50%;
  text-align: center; 
  padding: 1.5rem 0;
}

.mob .showroom_mob_item a {
  text-decoration: none;
  color: $linkGray;
  font-size: 1.25rem;
}

.mobile_video_card {
  position: fixed;
  width: 100vw;
  top: 50%;
  left: 0;
  background: #fff;
  box-shadow: 0 0 1rem rgba(0,0,0,0.05);
  text-align: center;
  transform: translateY(-50%);
  cursor: pointer;
}

.mobile_video_card button {
  display: inline-block;
  height: auto;
  width: auto;
  margin: 0.75rem 0;
}

.mob {
  position: relative;
}

.videoMob {
  //position: fixed;
  width: 100vw;
  height: auto;
  left: 0;
  top: 0;
  /*box-shadow: 0 0 2.5rem rgba(0,0,0,0.1);*/
  border-top: 1px solid #ddd;
  background: #fff;
  z-index: 100;
  height: 100vh;
  position: absolute;
}

.videoMob video {
  margin-bottom: 3.5rem;
  transform: scale(1.45);
}


.videoMob button {
  background: $linkGray;
  color: #fff;
  width: auto;
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  height: 2.5rem;
  font-weight: 500;
  display: inline-block;
  margin: 0.75rem 0;
}

.videoMob.playing {
  display: block;
}

.videoMob .wrapper {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}


.mobile_video_header {
  margin-top: 1rem;//3rem;
  text-align: right;
  /*padding-right: 0.75rem;*/
}

.mobile_video_header .popup_close {
  margin: 0;
  padding: 1.5rem;
  height: auto;
  font-size: 3rem;
  line-height: 1.5rem;
  background: transparent;
  color: $linkGray;
}



.showroom_video_wrapper .preloader {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.showroom_video_wrapper.loading.playing .preloader {
  display: block;
}


#hotzone {
  position: relative;
}

#hotzone.single .slick-slide {
  opacity: 1!important;
}

#hotzone.single .slick-slider {
  width: 100%;
  margin: 0;
}

#hotzone.single .showroom-slider__banners .slick-slide img {
  transform: scale(1);
}

#hotzone .showroom-slider__banners::before, #hotzone .showroom-slider__banners::after {
  display: none;
}

#hotzone.mobile .showroom-slider__nav {
  width: 250%;
  margin-left: -75%
}


#hotzone.mobile .showroom-slider__nav .showroom-arrow.prev {
  left: 30%;
  z-index: 200;
}

#hotzone.mobile .showroom-slider__nav .showroom-arrow.next {
  right: 30%;
  z-index: 200;
}

.splay {
  width: 2.5%;
  height: auto;
  position: absolute;
  transform: translate(-50%, -100%);
}

.splay_hide {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.splay_show {
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
}



.video_icon_placeholder {
  width: 80px;
  height: 60px;
  background: #eee;
}

.gallery_dots div.slide .video_icon_placeholder {
  margin: 0.25rem 0
}


.video_slide {
  position: relative;
}

.video_slide.videoready svg {
  opacity: 0
}

.video_slide video {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#listing_contact .buttons {
  /*justify-content: center;*/
}


#listing_contact .buttons >button {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}



#hotzone {

  .showroom-slider__banners {
    .slick-slide {
      padding: 4.5rem 0;
      opacity: 0.5;
      filter: grayscale(1);
      transition: all 0.5s ease-in-out 0.2s;

      img {
        transform-origin: 50% 50%;
        transition: transform 0.5s ease-in-out 0.2s;
        transform: scale(0.7);
      }
    }

    .slick-current +.slick-active {
      //transform: scale(1.25);
      filter: none;
      opacity: 1;

      img {
        transform: scale(1.2);
      }
    }

    &::before, &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 10rem;
      height: 100%;
      background: linear-gradient(90deg ,rgba(255,255,255,1), rgba(255,255,255,0));
      z-index: 200;
    }

    &::after {
      right: 0;
      left: auto;
      background: linear-gradient(-90deg ,rgba(255,255,255,1), rgba(255,255,255,0));
    }
  }





  .showroom-slider__nav {
    margin: 1.5rem 15rem;
    line-height: 3rem;

    .showroom-arrow {
      position: absolute;
      left: -15rem;
      top: 0.5rem;
      cursor: pointer;
    }

    .showroom-arrow.next {
      left: auto;
      right: -15rem;
    }

    .slick-slide {
      text-align: center;
      color: $gray;
    }

    .slick-current +.slick-active {
      color: $green;
      font-weight: 500;
    }
  }
}



  .showroom-arrow {
    width: 2rem;
    height: 2rem;
    fill: #e5e5e5;
  }

  .showroom-arrow.next {
    transform:scale(-1);
  }








@media all and(min-width:700px) {
  #showroom_links .showroom_link_icon {
    display: none;
  }
}

@media all and(max-width:700px) {
  #hotzone {
    .showroom-slider__banners {
      width: 200%;
      margin: 0 -50%;
    }
    .showroom-slider__banners .slick-slide {
      padding: 1.5rem 0;
    }

    .showroom-slider__nav  {
      margin: 0.75rem 1.5rem;
      
      .showroom-arrow.prev {
        left: -1.5rem
      }
      .showroom-arrow.next {
        right: -1.5rem
      }
    }
  }
}

@media all and(max-width: 1600px) {
  #hotzone {

    .showroom-slider__nav  {
      margin: 0.75rem 1.5rem;
      
      .showroom-arrow.prev {
        left: -1.5rem
      }
      .showroom-arrow.next {
        right: -1.5rem
      }
    }
  }
}


/**********************/
/***** USER ICON ******/
/**********************/

.user {
  display: flex;
  align-items: center;
  margin-right: 1.5rem;

  .user_pic {
    padding-right: 0.75rem;
  }

  .user_pic img {
    width: 3rem;
    border-radius: 3rem;
  }

  .user_company {
    color: #777;
  }
}




/**********************/
/*** SHOWROOM PRICE ***/
/**********************/


.card_price {
  text-decoration: none;
  position: relative;
}

.card_price .blur {
  opacity: 0;
  user-select: none;
}

.card_price .card_price_show {
  position: absolute;
  font-weight: 600;
  color: $green;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.tabs_tab .prop.base-aircraft-price-usd .prop_label {
  color: $linkGray;
  font-weight: 500;
}

.tabs_tab .prop.base-aircraft-price-usd .prop_value {
  color: #000;
  font-weight: 600;
  position: relative;
}

.tabs_tab .prop.base-aircraft-price-usd .prop_value span {
  font-size: 1.125rem;
}

.tabs_tab .prop.base-aircraft-price-usd.unregistered .prop_value span {
  filter: blur(0.25rem);
  user-select: none;
}

.tabs_tab .prop.base-aircraft-price-usd.unregistered .prop_value a {
  padding: 0 0.5rem;
  text-decoration: none;
}

.compare_anon .proprow_base_aircraft_price_usd td {
  filter:blur(0.25rem);
  user-select: none;
}

tbody.hdn td {
  user-select: none;
}


.page_compare_showrooms .container_hscroll {
  position: relative;
}

.compare_register_link {
  position: absolute;
  left: 0;
  width: 100%;
}

.compare_register_link span {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
}


.empty_compare_background {
  background: #eee;
}

.empty_compare_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 50rem;
  margin: 0 auto;
  padding: 1.5rem 0;
}

.empty_compare .empty_compare_text {
  flex-grow: 2;
  font-size: 1.25rem;
}

.empty_compare_button{
  flex-shrink: 0;
  text-align: center;
  padding-left: 1.5rem;
}

.empty_compare_button a {
  text-decoration: none;
  font-weight: 500;
  color: $linkGray;
}

.empty_compare_button span{
  display: block;
}

.proprow_view .empty_compare_button a {
  border: 2px solid #eee;
  display: block;
  padding: 1rem;
}


.showroom_link_icon {
  position: relative;

  picture{
    position: absolute;
    top: 0;
    left: 0;
  }
}




/**********************/
/** SHOWROOM CONTACT **/
/**********************/



#listing_contact #region {
  max-width: 90vw;
  width: 20rem;
  text-align: left;
}

#listing_contact .showroom_contacts {
  display: table;
}

.showroom_contacts > .showroom_contact a.asocial {
  display: block;
}

.showroom_contacts > .showroom_contact {
  display: table-row;
}

.showroom_contacts > .showroom_contact > div {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  padding: 0.25rem 0.75rem;
  border-bottom: 2px solid #f1f1f1;
}

.showroom_contacts > .showroom_contact:last-child > div {
  border: none;
}

.showroom_contacts > .showroom_contact a {
  font-size: 1.125rem;
  text-decoration: none;
  white-space: nowrap;
}

#listing_contact h2.dealers_list_title {
  color: $title;
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid $title;
}

.showroom_contact .title{
  font-weight: 500;
  font-size: 1.25rem;
}


/**********************/
/******* HEADER *******/
/**********************/


header#header {
  //line-height: 3.5;
  height: 3.5rem;
  box-shadow: 0 0 0.75rem rgba(0,0,0,0.1);
  position: fixed;
  width: 100vw;
  z-index: 900;
  max-width: 100vw;
  transition: box-shadow 0.5s ease-in-out 0s;
}

.not-scrolled header#header {
  box-shadow: 0 0 0 rgba(255,255,255,0);
}

#logo_img {
  max-width: 100%;
  height: auto;
  display: block;
  padding-bottom: 0.4rem;
}

#content_wrapper {
  padding-top: 3.7rem;
}

.header_row.container {
  /*background: url(hh.png);
  background-size: 100% auto;*/
}

.header_cell_menu {
  flex-grow: 10;
}

.header_cell_logo {
  width: 11.5rem;
  margin-right: 2.5rem;
}


#logo {
  display: block;
  height: 3.5rem;
  padding-bottom: 0.25rem;
  width: 10rem;
}

.header_row {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header_cell {
  align-self: center;
}



#btn_menu svg {
  width: 1.75rem;
  margin: 0;
}

#btn_menu {
  width: 1.75rem;
  margin-left: 0.75rem;
}

#block_login {
  position: absolute;
  top: 3rem;
}

ul#menu {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
}

ul#menu li {
  padding: 0 1.5rem;
}
ul#menu li a {
  text-decoration: none;
  display: block;
  color: $gray;
  font-size: 12px;
  font-weight: 400;

  svg {
    margin-bottom: 0.15em;
  }

  &:hover {
    svg {
      fill: $buttonHover
    }
    //color:$buttonHover
  }

  &.active {
    color: $green;
    font-weight: 600;
    svg {
      fill: $green
    }
  }

}

.header_cell_buttons {
  display: flex;
  flex-direction: row;
}

.header_row {
  position: relative;
}



.openMenu_true #menu_main {
  display: block;
}


.menu_popup {
  border-top: 1px solid #eee;
  position: absolute;
  top: 100%;
  right: 0rem;
  display: none;
  background: #fff;
  box-shadow: 0 0 1rem rgba(0,0,0,0.1);
}

.menu_popup ul {
  list-style: none;
  line-height: 1.5;
  border-bottom: 1px solid #eee;
  text-align: right;
  margin: 0;
  padding: .75rem 0 calc(.75rem - 1px);
}
.menu_popup ul:last-child {
  border-bottom:none;
}

.menu_popup ul a {
  display: block;
  padding: 0 1.5rem;
  text-decoration: none;
  color: #000;
  min-width: 12rem;
}

.menu_popup ul a:hover {
  background: #eee;
}


.header_wrapper {
  position: relative;
  z-index: 20;
  background: #fff;
  max-width: 100vw;
}

.menu_popup {
  position: absolute;
  z-index: 10;
}



.header_menu li.active {
  position: relative;
  color: $green;

  a {
    color: $green;
  }

  svg {
    fill: $green;
  }
}

.showroom_button.playing {
  z-index: 300;
}


.header_buttons button.can_compare {
  color: $green;
  font-weight: 600;
}

.header_buttons button.can_compare svg{
  fill: $green;
}


#menu_profile {
  display:  none;
}

#menu_profile.profileOpen_true {
  display: block;
}





#btn_user img{
  width: 1.25rem;
  height: 1.25rem;
  display: block;
  margin: 0 auto;
  border-radius: 1rem;
}

#menu_main {
  display:  none;
}

#menu_main.menuOpen_true {
  display:  block;
}

#registrationForm {
  border: 2px solid #f0f;
}


.listing_image_palette {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem;
  background: #fff;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.75rem rgba(0,0,0,0.05);
}

.listing_image_palette_card {
  width: 6rem;
  padding: 0 0.5rem;
  cursor: pointer;
}

.tabs_navigation {
  margin: 0;
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

.tabs_navigation .tab {
  padding: 0 0rem;
  flex-grow: 1;
  text-align: center;
}

.tabs_navigation .tab a {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  font-size: 1.25rem;
}




.tabs_tab {
  display: flex;
  flex-wrap: wrap;
}

.tabs_tab .prop {
  width: 50%;
  display: flex;
  border-bottom: 2px solid #eee;
  line-height: 1.2;
  padding: 0.9rem 0 0.3rem 0;
}

.tabs_tab .prop .prop_label {
  width: 50%;
  color: #777;
}

.listing_image_box img {
  width: 100%;
}


#listing_main {
  margin-top: 4.5rem;
}

#listing_main .tabs {
  position: relative;
}

#listing_main .tabs .tabs_navigation {
  position: absolute;
  width: 100%;
  transform: translateY(-100%);
  top: -2rem;
}



#addFloater {
  position: absolute;
  left: 5.25rem;
  top: 0;
  width: 10rem;
  width: 4.5rem;
}

.header_buttons {
  position: relative;
}


#addFloater.down #btn_plus_listing {
  /*left:-2.5rem;
  top: 3.5rem;*/
  transform: translate(-2.5rem, 3.5rem);
  z-index: 1;
  opacity: 1;
}
#addFloater.down #btn_plus_request {
  transform: translate(2.5rem, 3.5rem);
  /*left:2.5rem;
  top: 3.5rem;*/
  z-index: 1;
  opacity: 1;
}


#addFloater button {
  background: #fff;
  box-shadow: 0 0 0.5rem rgba(0,0,0,0.2);
  width: 4.5rem;
  height: 4.5rem;
}

#addFloater button svg {
  fill: $green;
}


#btn_plus_listing, #btn_plus_request {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5rem;
  padding: 0;
  height: 4.5rem;  
  transition: all 0.25s ease-in-out 0s;
  z-index: -1;
  opacity: 0;
  transform-style: preserve-3d;
}


.header_icon_placeholder {
  height: 1.25rem;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAG1BMVEVHcEyZmZmWlpabm5uampqZmZmYmJicnJyZmZnZG6OiAAAACXRSTlMAgBFCeXhDEkHy3BrnAAAAKUlEQVQI12NgoDZQNoKx2B1FFKBMNkFBAyiTWVCwAcpkchRVwNRGNQAAHPACnB0jOzwAAAAASUVORK5CYII=') center center no-repeat;
}


/**********************/
/******** AI **********/
/**********************/

.ai {
  position: absolute;
  z-index: 400;
  top: 100%;
  right: 1.5rem;
  transition: all 0.5s ease-in-out 0s;



  &__form {
    display: flex;
    align-items: center;

    button {
      line-height: 1rem;
      width: 2.75rem;
      padding: 0;


      svg {
        width: 2.5rem;
        height: 2rem;
        display: block;
      }
    }
    

    .input_box {
      padding: 0.5rem 0.5rem;
      outline: 1px solid #ddd;
      text-align: left;
      background: #eee;
      border-radius: 1rem;
      overflow-wrap: break-word;
      max-width: 100%;
      min-width: 15em;
      margin-right: 0.25rem;
      outline: none!important;
      
      &:focus {
        outline: none!important;
      }
    }
  }

  &__e {
    width: 7.5rem;
    height: 7.5rem;
    transition: all 0.5s ease-in-out 0s;
  }

  &__chat {

    position: absolute;
    right: calc(100% + 0.75rem);
    top: 0.5rem;
    background: #fff;
    padding: 0.75rem;
    border: 1px solid #eee;
    border-radius: $borderRadius 0 $borderRadius $borderRadius;
    box-shadow: 0 0 0.5rem rgba(0,0,0,0.5);
    display: none;

    &--title {
      font-weight: 500;
      font-size: 1.25rem;
      position: relative;

      .beta {
        position: absolute;
        right: -2rem;
        top: 0;
        font-size: 12px;
        color: #999;
      }
    }

    &.opened {
      display: block;
    }

    &--messages {
      //padding: 0.5rem;
      margin: 0.5rem 0;
      
      .ai__msg {
        padding: 0.5rem;
        border-radius: $borderRadius 0 $borderRadius $borderRadius;
        margin-left: 0.75rem;
        margin-bottom: 0.75rem;
        background: #eee;
        border: 1px solid #eee;

        &.me {
          margin-right: 0.75rem;
          margin-left: 0;
          border-radius: 0 $borderRadius $borderRadius $borderRadius;
        }
      }

    }

    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
      }
    }
  }
}

.closed.ai {
  right: calc(100% - 3.125rem);
  top: 0.5rem;
  pointer-events: none;

  .ai__e {
    width: 1.25rem;
    height: 1.25rem;
    opacity: 0;
  }
}

button.tease svg{
  animation: 2s infinite eblink;
}




@keyframes eblink {
  from {
    fill: $green
  }
  49% {
    fill: $green
  }
  50% {
    fill: $gray
  }
  to  {
    fill: $gray
  }
}


/**********************/
/****** FORMS *********/
/**********************/


input, textarea, input[type=number] {
  border-radius: 0.5rem;
  border: 1px solid $border;
  background: $almostWhite;
  padding: 0.25rem 0.5rem;
  line-height: 1.5!important;
  margin: 0;
  max-width: 100%;
}

textarea {
  min-height: 8rem
}

form label {
  display: block!important;
  line-height: 1.2;
  font-size: 14px;
}

.form-button button {
  /*background: $special;
  border-radius: 0.625rem;
  padding: 0.25rem 1rem;
  display: block;
  font-weight: 500;
  width: auto;
  color: #fff;
  font-size: 1.1rem;*/
  background: #000;
  width: auto;
  padding: 0 2rem;
  border-radius: 0.9rem;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  line-height: 2.6rem;
  height: 2.5rem;
  font-size: 1.15rem;
}
/*
.form-button button:hover {
  background: $specialVar;
}

.form-button button.secondary {
  background: $button;
}

.form-button button.secondary:hover {
  background: $buttonHover;
}*/


.form-text label {
  display: block;
}

.form-number label {
  display: block;
}

.form-number input {
  line-height: 1.5;
  margin: 0;
  border-radius: 0.125rem;
  border: 1px solid #ddd;
  padding: 0.25rem 0.5rem;
}

input[type="checkbox"] {
  width: 1.5rem!important;
}


.form-text label {
  display: block;
}

.form-text input {
  line-height: 1.5;
  margin: 0;
  border-radius: $borderRadius;
  border: 1px solid #ddd;
  padding: 0.25rem 0.5rem;
}

.range_select_inputs input {
  width: 10rem;
  max-width: 80%;
}

.range_select {
  border: 1px solid #eee;
  border-radius: 0.75em;
  padding: 0.75em 0.75em;
}



.form-element-wide {
  width: 100%!important;
}

.range_select_inputs .label {
  display: none;
}

.filters.m4 > div.toggler{
  display: none;
}


.required::after {
  content: "*";
  position: absolute;
  color: #d00;
  font-size: 1.5rem;
  right:  0.75rem;
  top: 0.25rem;
}

.required {
  position: relative;
}

.agree_box {
  margin-bottom: 0.5rem;
}


#usergate .captcha_form {
  max-width: 25rem;
  margin: 0 auto;
}

.agree_box.error a{
  color: #d00;
}

.captcha_stuff {
  color: #ccc;
  font-size: 12px;
  margin-top: 0.5rem;
}

.captcha_stuff a {
  color: #aaa;
  padding: 0 0.25rem
}

.form-element.required::after {
  top: 0;
}

.form-checkbox label {
  display: inline-block!important;
}


main .descr .field_box > div.form-element , main form .descr .form-element {
  width: 100%;
}


.form-submit {
  flex-grow: 1;
  margin-top: 1rem;
}

#form_createListing form >div {
  flex-grow: 1;
}



.select-innard__menu {
  z-index: 100!important;
}


form .select-innard__single-value {
  color: #666;
}



/*ERRORS*/


.password_errors .password_error {
  color: #d00;
}

.error label {
  color: #d00;
  font-weight: 600;
}

form .error .select-innard__control {
  border-color: #d00;
}

form .error input {
  border-color: #d00;
}

form .error .dzu-dropzone {
  border-color: #d00;
}

#contact_us_form .form-element {
  width: 100%;
}

.sortable_container.error .sortable_box {
  border-color: #d00;
  border-width: 2px;
  border-radius: 0.125rem;
}

.sortable_container.error .sortable_box svg {
  fill: #d00;
}


.form-single-file_container {
  border: 1px solid #ebebeb;
  background: #ebebeb;
  border-radius: 0.5rem;
  display: flex;
  line-height: 2.1;
  height: 2rem;
  overflow: hidden;
  
}

.form-single-file_name {
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow:hidden;
  padding: 0 0.5rem;
  font-size: 1rem;
  line-height: 2;
}

.form-single-file_add,
.form-single-file_close{
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  line-height: 2.2;
  text-align: center;background: #eee;
  position: relative;
}

.form-single-file_add svg,
.form-single-file_close svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.25rem;
  cursor: pointer;
  height: 1.25rem;
  stroke-width: 4;
}

.no_file .form-single-file_name {
  color: #777;
  
}


#form_createListing .form-submit button {
  //margin-right: 2rem;
}


#lostPassword {
  max-width: 30rem;
}

#form_oblivion .form-text {
  width: 100%;
}



.button-row, .form-submit.button-row, .form_actions {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

/**********************/
/*** LOGIN/REGISTER ***/
/**********************/


.form_block .tabs {
  display: flex;
  list-style: none;
  padding: 0;
  font-size: 1.2rem;
  font-weight: bold;
  justify-content: space-evenly;
}

.form_block .tabs > li {
  cursor: pointer;
  padding: 0.25rem 1.5rem; 
}

.form_block .tabs > li.active {
  border-bottom: 2px solid #444;
}

#usergate {
  width: 25rem;
}
#usergate .form-element {
  width: 100%;
}

#usergate .form_container h3 {
  display: none;
}

#usergate form {
  max-width: 100%;
  width: 25rem;
}


.margin_container {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}


#form_oblivion .form-text{
  /*width: 400px;*/
  max-width: 100%;
}

#form_oblivion .password_errors {
  max-width: 400px;
  padding-bottom: 0.5rem;
}


/**********************/
/****** SELECT ********/
/**********************/


form .select-innard__indicator {
  padding: 6px!important;
}

form .select-innard__input-container {
  margin: 0;
  
  padding-top: 0;
  padding-bottom: 0;
}

form .select-innard__value-container {
  padding-top: 0;
  padding-bottom: 0;
}

form .select-innard__single-value {
  font-weight: 500;
  color: #000;
}

form .select-innard__control {
  min-height: 32px;
  background: $almostWhite;
  border: 1px solid #ebebeb;
  border-radius: 0.5rem;
}

form .select-innard__indicator svg {
  fill: #333;
}

.form_actions {
  display: flex;
  justify-content: space-between;
}

button.secondary {
  color: $linkGray;
  background: transparent;
  font-size: 1rem;
  padding: 0;
}

.disabled {
  opacity: 0.4
}

#react-select-model-listbox,
#react-select-brand-listbox,
#react-select-category-listbox{
  z-index: 400;
}



/**********************/
/****** COMMON ********/
/**********************/


img:not(.banner-image), svg, video {
  max-width: 100%;
  height: auto;
}

.end {
  align-self: center;
}

.right_block {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.right {
  text-align: right;
}

.image-box, .image-box-banner {
  position: relative;
}

.float_right {
  float: right;
}

.image-box img,.image-box-banner img,
.image-box .image-placeholder-span,.image-box-banner .image-placeholder-span {
  /*position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;*/

    position: absolute;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
}

.image-box-banner img {
  width: 100%;
  height: 100%;
}


.image-box-banner.wide-blur-banner img {
  width: auto;
}

.image-box img{
  display: block;
}

.image-box svg {
  display: block;
}







.slick-track {
    display: flex !important;
    -webkit-backface-visibility: hidden; 
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    backface-visibility: hidden; 
}
.slick-slide {
    height: inherit !important;
    display:none;
}

.slick-slide:first-child {
  display: block;
}


.slick-slide >div {
  height: 100%;
}

.slick-slide .slide {
  height: 100%;
}

.slick-slide a {
  display: block;
  height: 100%;
}

.slick-slide .image-box-banner {
  height: 100%;
}








.image-box {
  /*border-radius: 0.625rem;*/
  overflow: hidden;
}

.transparent {
  opacity: 0;
}

.image-box-std img{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}


.image-box-banner img {
  display: block;
}

.image-box-banner svg {
  background: #fff;
  display: block;
  width: 100%;
}

.news_image_container {
  width: 20vw;
  max-width: 15rem;
  margin-right: 0.75rem;
}



.bar a {
  text-decoration: none;
  color: $linkGray;
}

.bar .image-box {
  width: 20vw;
  max-width: 15rem;
  margin-right: 0.75rem;
}

.bar .card_wrapper, .bar .news_wrapper {
  display: flex;
}

.bar .card_wrapper h3, .bar .news_wrapper h3 {
  margin-top: 0;
}

.readmore {
  font-weight: bold;
}

.readmore svg {
  padding-left: 1.5rem;
  stroke: #000;
}

.grecaptcha-badge { 
    visibility: hidden;
}




.wide-blur-banner {
  position: relative;
  overflow: hidden;
}

.wide-blur-banner img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.wide-blur-banner .background_blur {
  width: calc(100% + 6rem);
  height: calc(100% + 6rem);
  position: absolute;
  top: -3rem;
  left: -3rem;
  background-size: 100% 100%;
  filter: blur(1.5rem);
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden; 
  backface-visibility: hidden; 
  transform-style: preserve-3d;
}

.header_line .back {
  min-width: 2rem;
  padding-right: .5rem;
  flex-shrink: 0
}

/**********************/
/****** SEARCH ********/
/**********************/


.form_group_search {
  display: flex;
  flex-direction: row;
  /*margin-bottom: 1.5rem;*/
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}

.form_group_search > div.form-element {
    width: 25%;
    box-sizing: border-box;
    padding: 0 0.75rem;
    margin-left: 0;
    margin-right: 0;
}


.notFound {
  text-align: center;
  font-size: 1.5rem;
  color: #aaa;
  padding: 2.5rem;
  border-bottom: 1px dotted #eee;
}


#form_searchListing {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

#form_searchListing form {
  display: block;
  width: 100%;
}

#form_searchListing form > div {
  flex-direction: row;
  display: flex;
}

.form_group_search {
  width: calc(100% - 5rem);
}



#form_searchListing .form-button {
  width: 2rem;
  margin-right: 0;
  margin-left: 0;
}


#form_searchListing .form-button button {
  width: 100%;
  background: #fff;
  padding: 0 0.25rem;
}

#form_searchListing .form-button button svg {
  width: 2rem;
  height: 2rem;
}


#form_searchListing form .form_search_row_filters.hide {
  display: none;
}

#form_searchListing .form-element.form-select {
  flex-grow: 2;
  width: 20%;
}

#form_searchListing .form-element.form-text {
  flex-grow: 3;
  width: auto;
}

#form_searchListing .form_group_search {
  flex-grow: 2;
}

#form_searchListing .form-element.form-button {
  padding-left: 1.5rem;
  padding-right: 0;
}


.listing_search_nav {
  border-bottom: 2px solid #b9b9b9;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.listing_search_nav h3{
  margin: 0;
}

.listing_search_nav span.button_saveSearch {
  width: 6rem;
  cursor: pointer;
  //border: 1px solid #444;
  padding: 0.5rem 0.5rem;
  border-radius: 0.625rem;
  background-color: #b9b9b9;
  font-weight: 500;
  text-align: center;
}

.listing_search_nav span.button_backSearch {
  width: 6rem;
  cursor: pointer;
  padding: 0.5rem;
}





.search_saved > div{
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid #eee;
}

.search_saved .updated {
  padding: 0 0.75rem;
  color: #aaa;
  font-size: 0.875rem;
}

.search_saved .query {
  flex-grow: 2;
}

.search_saved .action {
  padding: 0.25rem 0;
  display: flex;
}

.search_saved .action button{
  background: #000;
  padding: 0rem 0.75rem;
  width: auto;
  height: 2rem;
  line-height: 2.2;
  color: #fff;
  font-weight: 500;
  border-radius: 0.625rem;
  margin-left: 0.5rem
}




/**********************/
/****** DEALERS *******/
/**********************/


.dealers_list {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
}

.dealer-card {
  text-align: center;
  padding: 1.5em;
  text-decoration: none;
  color: $title;
}

.dealer-card h2 {
  font-size: 14px;
  margin-bottom: 0;
  margin-top: 1.5rem;
  color: #aaa;
  font-weight: 300;
}


/**********************/
/****** LISTING *******/
/**********************/



ul.features {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.features {
  column-count: 4;
}


.feature svg {
  width: 1rem;
  height: 1rem;
  padding-right: 0.25rem;
  vertical-align: -0.125rem;
}



.listing_card .listing_image {
  position: relative;
}

.listing_card .listing_image svg,
.listing_card .listing_image img{
  width: 100%;
  height: auto;
}


.listing_card_info {
  /*position: absolute;*/
  width: calc(100% - 1.5rem);
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  padding: 0.25rem 0;
  box-sizing: border-box;
  margin: 0.75rem;
  //background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 12%, rgba(0,0,0,0) 100%);
  //border-radius:  0 0 .625rem .625rem;
  text-align: left;
}

.listing_card_info .card_row {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  //color: #fff;
}

.card_price {
  background: transparent;
  border-radius: 1.5rem;
  color: $green;
  font-weight: 600;
  display: inline-block;
  /*padding: 0.55rem 1rem 0.44rem;*/
  margin-left: 0.75rem
}


.listing_price  {
  position: relative;

}

.listing_price .price_old {
  position: absolute;
  left: 0;
  top: -1.2rem;
  font-size: 1.2rem;
  color: #777;
  text-decoration: line-through;
}

.price_suffix {
  color: #666;
  font-size: 1rem;
  margin-left: 0.5rem;
}


.listing_card_info  h3 {
  margin: 0.5rem 0;
}

.listing_card {
  position: relative;
}

.listing_card .actions {
  top: 0.75rem;
  left: 0.75rem;
  position: absolute;
}



.compare_listing_card .actions .btni,
.listing_card .actions .btni {
  background: #efeff3;
  padding: 0!important;
  border-radius: 10em;
  position: relative;
  display: block;
  height: 2rem;
  width: 2rem;
  display: inline-block;
  margin-right: 0.5rem;
}

.compare_listing_card .actions .btni svg, 
.listing_card .actions .btni svg {
  height: 1.2rem;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 1.2rem;
  fill: #aaa;
}

.compare_listing_card .actions .btni.btn_like svg, 
.listing_card .actions .btni.btn_like svg {
  width: 0.75rem;
  height: 0.75rem;
}


.compare_listing_card .actions .btni.btn_like svg,
.listing_card .actions .btni.btn_like svg,
.btn_like svg {
  fill: #aaa;
}

.listing_card .actions .btni.btn_compare.toggled svg {
  fill: $green;
}

.compare_listing_card .actions .btni.btn_like.like svg,
.listing_card .actions .btni.btn_like.like svg,
.btn_like.like svg {
  fill: $like;
}

.hasicon svg {
  stroke: #fff;
  padding-right: 0.25rem;
  vertical-align: -0.1rem;
} 





#listing_contact {
  display: flex;
  flex-direction: column;
  align-items: center;  
  justify-content: space-between;
  width: 100%;
}

#listing_contact h2 {
  //color: #0078c4;
  color: #1a7408;
  font-weight: 600;
  font-size: 2rem;
}

/*#listing_contact button {
  background: #000; 
  background: linear-gradient(to right, #000, #53a90f);
}*/



#listing_contact > * {
  margin: 0;
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}


/*#listing_contact button{
  max-width: 15rem;
  text-align: center;
  display: inline-block;
  width: auto;
}*/


.contact_row {
  align-self: center;
  padding: 0.75rem 0;
}

.contact_row > * {
  margin: 0;
  align-self: flex-end;
}

.seller {
  display: flex;
  padding-right: 0.75rem
}

.seller .seller_image{
  border-radius: 10rem;
  width: 4rem;
  margin-right: 0.75rem;
}

.seller_image img {
  border-radius: 3rem;
  display: block;
}

.seller .seller_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0;
  text-align: left;
}

.seller .seller_type {
  color: #777;
}

.seller .seller_name {
  font-weight: 500;
}


.va {
  min-height: 3rem;

  h3 {
    line-height: 1.2rem;
  }
}


.va >.card_col {
  align-self: center;
}

.listing_ttsn {
  padding-left: 1rem
}

.listing_card_info .card_row.row_simple {
  justify-content: left;
}



.listing_sold_message {
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
  background :#eee;
  padding: 1.5rem;
  font-weight: 500;
}


.listing .image-box-banner img {
  width: auto;
}





.dealer img {
  display: block;
  max-width: 35vw
}

#listing_contact {
  align-items: center;
}

#listing_contact .contact_row >div {
  align-self: center;
}

.header_actions button.black {
  background: #000;
  padding: 0 1rem;
}



#listing_contact a.seller{
  text-decoration: none;
  color: $linkGray;
}



.file_download .file_download_icon {
  vertical-align: -0.2rem;
  margin-right: 0.25rem;
}

.file_download {
  margin-bottom: 0.75rem;
}

.file_download_type {
  text-transform: capitalize;
  font-weight: 500;
}

.file_download a {
  text-decoration: none;
  color: $linkGray;
}

.own_listing_mobile {
  display: none;
}

.own_listing {
  display: inline-block;
}

section.own_listing_mobile {
  margin: 0;
  padding: 1.5rem 0 0 0;
  text-align: right;
}


/**********************/
/** NEW LISTING CARD **/
/**********************/

.listing_image_wrapper {
  position: relative;
}

.listing_wrapper.cs {
  padding: 0;
  background: $almostWhite;
}

.cs .listing_card_info {
  margin: 0;
  border-radius: 0;
  width: 100%;
}

.cs .listing_card_info h3 {
  margin: 0;
}

.listing_image img {
  display: block;
}

.cs .card_row_bottom {
  color: #000;
  text-decoration: none;
  font-weight: 500;
  color: #555;
  align-items: center;
  padding: 0.5rem 0;
  display: block;
  border-bottom: 2px solid #d8d8d8;
}

.listing_card a,
.compare_listing_card a,
.card_a {
  text-decoration: none;
  color: inherit;
  background: #fff;
  //border-radius: $borderRadius;
  font-size: 1rem;
  overflow: hidden;

  h3 {
    font-size: 1rem;
    font-weight: 600;
  }
}


.cs .row_simple {
  line-height: 2;
  display: flex;
}

.cs .card_row_bottom .listing_ttsn svg{
  stroke: #555;
  vertical-align: middle;
  padding-bottom: 0.2rem;
}

.cs .card_row_bottom .card_col {
  align-self: center;
}

.cs .card_col .actions {
  padding-top: 0;
}

.cs .listing_sn {
  padding-left: 0.75rem;
}

.listing_image img {
  text-align: center;
  position: relative;
}

.listing_image img::before{
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  font-weight: 500;
  font-size: 1.25rem;
  color: #aaa;
}





#form_createListing form {
  display: flex;
  flex-wrap: wrap;

  .field_group.fms,
  .field_group.autopilot,
  .field_group.transponder,
  .field_group.vhf_com,
  .field_group.vhf_nav,
  .field_group.gps{
    width: 25%;
    padding: 0 0.75rem;
    box-sizing: border-box;
    background: #f8f8f8;
    border: 3px solid #fff;

    .field_box {
      flex-direction: column;
    }
    .form-element {
      width: auto;
    }
  }
}

.field_group.engine .field_box {
  background: #f8f8f8;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: 3px solid #fff;
}

#form_createListing form .field_group {
  width: 100%;
  
}





/**********************/
/******** NEWS ********/
/**********************/

.news_announce {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}

.news_announce .article {
  flex-grow: 5;
}

.news article {
  margin-bottom: 1.5rem;
}

.article > :first-child {
  margin-top: 0;
}

@media all and (min-width: 1200px) {
  .news.bars {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5em;
  }

}





/**********************/
/****** COMPARE ********/
/**********************/

.container_hscroll {
  overflow-x: scroll
}

table#compare {
  display: inline-block;
}



table#compare th.values {
  text-align: right;
  padding: 0.25rem 0.75rem;
  font-weight: 500;
}

table#compare tr >*{
  border-bottom: 1px dotted #ddd;
}

table#compare tr:last-child >*{
  border-bottom: none;
}

table#compare tr.group th {
  padding: 1.25rem 0.75rem 0.25rem;
  border-bottom: 2px solid #aaa;
}

.image-box-compare {
  max-width: 400px;
  max-height: 300px;
}

table#compare .proprow_Image > * {
  border-bottom: none;
}

table#compare .proprow_Image th{
  opacity: 0;
}

table#compare .proprow_Title td {
  font-weight: 600;
  padding-top: 1.5rem;
  font-size: 1.25rem;
}

table#compare .proprow_Title th {
  opacity: 0
}

table#compare td{
  padding: 0.25rem 0.75rem;
  text-align: center;
  min-width: 12rem;
}

.compare_listing_card .listing_wrapper.bs {
  box-shadow: none;
}

.compare_listing_card a {
  padding: 0;
}

.compare_listing_card {
  width: 25rem;
  max-width: 100vw;
}

.btn_compare.toggled svg {
  fill: #000;
}


.showroom_compare_actions {
  display: flex;
  justify-content: right;
}

.showroom_compare_actions .btn_like svg{
  fill: #aaa;
}

.showroom_compare_actions .btn_like.like svg{
  fill: #b00;
}



.compare_small {
  display: block;
  max-width: 12rem;
  margin: 0 auto;
  position: relative;
}
.compare_small img {
  display: block;
}

.compare_small .likebox {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: right;
  background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,1));
}

.compare_small .likebox .btn_like {
  display: inline-block;
  background: transparent;
  width: 2.5rem;
}








.compare_anon tbody{
  position: relative;
}

.compare_anon tr.unregistered {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}

.compare_anon tr.unregistered td {
  position: relative;
}

.compare_anon tbody.hdn td {
  filter: blur(0.25rem);
  opacity: 0.4;
}

.compare_anon tr.unregistered td {
  display: block;
  top: 0;
  left: 0;
  filter: none;
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: auto!important;
  opacity: 1;
}

.compare_anon tr.unregistered td a {
  display: block;
  z-index: 100;
  line-height: 6rem;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 500;
}


.tbody_weights {
  position: relative;
}



/**********************/
/****** FOOTER ********/
/**********************/

footer {
  min-height: 3rem;
  background: $footer;
  color: #898989;
  padding: 1.5rem 0;
  position: relative;
}

#footer-logo {
  fill: #fff;
  width: 10em;
  height: 3em;
}

.social_icon {
  background: transparent;
  width: 3rem;
  height: 3rem;
  background: #343538;
  border-radius: 10rem;
  display: inline-block;
  //margin: 0 1.5rem;
  line-height: 2rem;
  position: relative;
}

footer .social_icon {
  margin-left: 0.75rem
}

.copyright {
  margin-top: 1.5rem;
  color: #bbb;
}

.social_icon svg{
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: #fff;
}


ul.menu {
  margin: 1.5rem 0;
  padding: 0;
  list-style: none;
}

ul.menu a {
  text-decoration: none;
}

ul.menu li {
  display: inline-block;
  padding: 0.25rem 1.5rem;
}

footer ul.menu a {
  color: #999;
}




.columns {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;

  .links {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    list-style: none;
    flex-wrap: wrap;

    a {
      color: #777;
      padding: 0 0.75rem;
    }
  }

  .text {
    color: #777;
  }
}




.compare_link {
  padding: 0.25rem 0;

  a {
    line-height: 1;
    text-decoration: none;
    display: flex;
    align-items: center;

  }

  .icon {
    padding-right: 0.5rem;
    width: 1.75rem;
  }

  .title {
    flex-shrink: 4;
  }

  svg {
    fill: $linkGray;
    display: block
  }

  .count {
    color: #aaa;
    padding-left: 0.5rem;
  }
}


.columns-2 {
  display: flex;
  
}

.columns-2 > div {
  padding: 0 0.75rem;
  width: 50%;
  box-sizing: border-box;
}


#form_add_request .columns-2 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}




.compare_link .title {
  flex-grow: 4;
  flex-shrink: 4;
}






/**********************/
/****** TABNAV ********/
/**********************/


ul.tabnav {
  padding: 0;
  list-style: none;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

ul.tabnav li {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
}

ul.tabnav li span {
  padding: 0 1.5rem;
  padding-bottom: 0.25rem;
}


ul.tabnav li.active span {
  border-bottom: 2px solid #444;
}









/**********************/
/****** FILTER ********/
/**********************/

.filters_wrapper{
  background: #fff;
  /*border-top: 2px solid $background;*/
  border-bottom: 1px solid #fff;
  margin: 1.5rem 0;
}

.filters {
  display: flex;
  margin: 1.5rem -0.75rem;
  flex-wrap: wrap;
  //border-top: 2px solid $background;
}


.filters >div {
  width: 25%;
}


.form_search_row_filters {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.form_search_row_filters >div {
  width: 33%;
}

.advanced_search {
  width: auto;
  padding: 0 .5rem;
}

.advanced_enabled {
  font-weight: 600;
}

/**********************/
/****** SHAWARMA ******/
/**********************/

div.description * {
  text-align: left!important;
  color: #212121!important;
  font-family: inherit!important;
}

p.description {
  white-space: pre-line;
}

blockquote {
  background: #f3f3f3;
  padding: 1.5rem 3rem;
  margin: 0;
}









/**********************/
/****** LISTING ******/
/**********************/


.listing_image_palette {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0.5rem;
  background: #fff;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.75rem rgba(0,0,0,0.05);
}

.listing_image_palette_card {
  width: 6rem;
  padding: 0 0.5rem;
  cursor: pointer;
}



.listing_image_box img {
  width: 100%;
}


#listing_main {
  margin-top: 4.5rem;
  margin-bottom: 3rem
}
.card_col {
  align-self: flex-end
}



.btn_compare_big svg{
  fill: #aaa!important;
}

.btn_compare_big.toggled {
  font-weight: 500;
  color: #000;
}

.btn_compare_big.toggled svg{
  fill: #000!important;
}



/**********************/
/***** TABS PROP ******/
/**********************/


.tabs_navigation {
  margin: 0;
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

.tabs_navigation .tab {
  padding: 0 0rem;
  flex-grow: 1;
  text-align: center;
  max-width: 17rem;
}

.tabs_navigation .tab {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  font-size: 1.25rem;
  cursor: pointer;;
}




.tabs_tab {
  display: flex;
  flex-wrap: wrap;
}

.tabs_tab .prop {
  width: 50%;
  display: flex;
  border-bottom: 2px solid #eee;
  line-height: 1.2;
  padding: 0.9rem 0 0.3rem 0;
}

.tabs_tab .prop .prop_label {
  width: 50%;
  color: #777;
}

#listing_main .tabs {
  position: relative;
}

#listing_main .tabs .tabs_navigation {
  position: absolute;
  width: 100%;
  transform: translateY(-100%);
  top: -1.5rem;
}

.tabs_navigation .tab {
 line-height: 2;
}

/*.tab.active {
  border-bottom: 3px solid #000;
}*/

.wide-blur-banner {
 max-height: 65vh; 
}


.tabs_navigation {
  border-bottom: 3px solid #ddd;
}

.tab.active {
  border-bottom: 0;
  position: relative;
}


.tab.active::after {
  width: 100%;
  content: "";
  height: 3px;
  position: absolute;
  bottom: -3px;
  left: 0;
  background: #000;
}


.prop_value svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: #000;
  stroke: #000;
  padding-right: 0.25rem;
  vertical-align: -0.2rem;
}

#listing_contact {
  margin: 1.5rem 0;
}


.listing_card {
  transition: transform 0.25s ease-in-out 0s;
}
.listing_card:hover {
  transform: scale3d(1.02, 1.02, 1);
}



.header_line {
  display: flex;
  margin: 1.5rem 0;
  align-items: center;
}

.header_line.container {
  margin: 1.5rem auto;
  padding: 0 0.75rem;
}



.header_line h1 {
  flex-grow: 2;
  font-size: 1.75rem;
  margin: 0;
}
/*
.header_actions {
  min-width: 30rem;
}


.header_actions button{
  display: inline-block;
  height: 2.4rem;
  font-size: 1.2rem;
  line-height: 2.2;
  padding: 0 1.5rem;
  margin-left: 0.75rem;
}

.header_actions button svg {
  width: 1.25rem;
  height: 1.15rem;
  fill: #000;
  vertical-align: middle;
  padding-left: 0.5rem;
  transform: translateY(-1px);
}


.header_actions .btnt {
//padding: 0;
border-radius: 3rem;
font-weight: 500;
color: #fff;
font-size: 1.25rem;

}

.header_actions .btnt svg {
  fill: #aaa;
  width: 1.75rem;
  height: 1.8rem;
  padding: 0;
}

.header_actions .btnt.like svg {
  fill: #c70000;
}



.header_actions button span{
  display: none;
}

.header_actions button{
  padding: 0 0.75rem;
  border-radius: 0;
  margin: 0;
  background: transparent;
  height: 2.4rem; 
  font-size: 1.2rem;
  width: auto;
  border-right: 2px dotted #aaa;
}


.header_actions button svg {
  margin: 0;
  padding: 0;
  width: 1.75rem;
  height: 1.75rem;
}

.header_actions {
  min-width: 12rem;
}

.header_actions button:last-child {
  padding-right: 0;
  border-right: 0;
}

.header_actions button.btn_like svg{
  fill: #aaa
}

.header_actions button.btn_like.like svg {
  fill: #c70000
}*/


.header_actions button {
  padding: 0 1rem;
  display: inline-block;
  margin-left: 0.5rem;
  width: auto;
}

.header_actions .btn_like {
  padding: 0;
  width: auto;
}

.header_actions .btn_like svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: #aaa;
}

.header_actions .btn_like.like svg {
  fill: #c70000;
}

.header_actions button svg {
  width: 1.4rem;
  height: 1.4rem;
  vertical-align: -0.25rem;
  padding-left: 0.5rem;
}

.header_actions {
  min-width: 26rem;
}



.header_actions button {
  background: transparent;
  font-size: 16px;
  padding: 0 0.25rem;
}

.header_actions button svg {
  width: 1.5rem;
  height: 1.5rem;
}

.header_actions button svg {
  vertical-align: -0.35rem;
}

.header_actions {
  text-align: right;
}



/**********************/
/**** SOCIAL SHARE ****/
/**********************/

.social_share_links {
  white-space: nowrap;
}

.social_share_links > * {
  display: inline-block;
}

.social_share_links svg{
 width: 3rem;
  padding: 0 0.5rem;
  height: 6rem;
}

.asocial svg{
  vertical-align: -0.25rem;
  padding-right: 0.25rem;
}

/**********************/
/**** PROFILE PAGE ****/
/**********************/



.profile_block_container {
  display: flex;
  flex-direction: row;
  margin: 0.75rem -0.75rem;
}

.profile_block_container > .profile_block{
  width: 50%;
  padding: 0 0.75rem;
}

.profile_block h2 {
  margin-top: 0;
}

.profile_avatar {
  width: 15rem;
  height: 15rem;
  border: 1px solid #eee;
  margin-bottom: 4.5rem;
}


avatar-image >div{
  background: url("data:image/svg+xml;base64,PHN2ZyBpZD0ic3ZnOCIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMzIgMzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8cmVjdCBpZD0icmVjdDMyMTIiIHg9IjQiIHk9IjQiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjYmNiY2JjIiBzdHJva2Utd2lkdGg9IjIiLz4KIDxwYXRoIGlkPSJwYXRoMzQ2NSIgZD0ibTE2IDExLjV2OSIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjYmNiY2JjIiBzdHJva2Utd2lkdGg9IjIiLz4KIDxwYXRoIGlkPSJwYXRoMzU0NyIgZD0ibTIwLjUgMTZoLTkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2JjYmNiYyIgc3Ryb2tlLXdpZHRoPSIyIi8+Cjwvc3ZnPgo=");  
  background-size: 70%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

avatar-image button {
  width: 2.5rem;
  height: 2.5rem;
  border: none!important;
  background: transparent!important;
}

avatar-image button svg {
  fill: #222;
}

avatar-image img {
  display: block;
}


.profile_block_form_main {
  display: flex
}

.profile_block_form_main input {
  width: 100%;
}

.profile_block_form_main >div.mc {
  padding-left: 1.5rem;
  flex-grow: 2;
}

.profile_avatar {
  max-width: 100%;
}

.profile_block_container > .profile_block_main {
  width: 60%;
}

.profile_block_container > .profile_block_social {
  width: 40%;
}

.profile_actions {
  margin: 1.5rem -0.75rem;
}

/*.profile_actions button {
  margin: 0 0.75rem;
}*/

avatar-image::after {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogPHBhdGggaWQ9InJlY3Q4NDMiIGQ9Im0wIDB2NTBhNTAgNTAgMCAwIDEgNTAtNTB6bTUwIDBhNTAgNTAgMCAwIDEgNTAgNTB2LTUwem01MCA1MGE1MCA1MCAwIDAgMS01MCA1MGg1MHptLTUwIDUwYTUwIDUwIDAgMCAxLTUwLTUwdjUweiIgZmlsbD0iI2ZmZiIgZmlsbC1vcGFjaXR5PSIuNSIgLz4KPC9zdmc+Cg==);
  display: block;
  top: 0;
  left: 0;
  position: absolute;
}






.profile_actions button.reject_btn {
  background: #aaa;
  float: right;
}


.profile_dealer_view .dealer_data {
  align-items: center;

}


/**********************/
/*** PRFILE CONTACT ***/
/**********************/

.avatar img {
  display: block
}

.contact_main_private .avatar img {
  border-radius: 20rem;
}

.contact_data .contact {
  display: flex;
  align-items: center;
}

.contact_data .contact_asocials a {
  display: block;
  line-height: 1.5;
  text-decoration: none;
  color: #000;
  font-size: 1.125rem;
  padding: 0.25rem 0.75rem;
  white-space: nowrap;
}

.contact_socials svg {
  fill: $linkGray;
}

.contact_socials .social_icon {
  margin-left: 0.75rem;
}



.contact_dealer {
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  padding: 0.75rem 0;
  background: #fff;
  margin-top: 0.5rem;
}

.contact_dealer .avatar {
  margin-right: 1.5rem;
}

.contact_dealer h2 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.contact_socials .social_icon {
  margin-left: 0;
  margin-right: 0.75rem;
}

.contact_contacts {
  display: flex;
  margin-top: 1rem;
}

.contact_contacts .contact_asocials {
  display: flex;
}

/*.contact_data .contact_asocials a {
  padding: 0 0 0 0.25rem;
}*/

#dealertype .active {
  border-bottom: 2px solid #212121;
}


.contact_data {
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

}

.contact_data .contact_contacts,
.contact_data .contact_avatar {
  flex-shrink: 0;
}

.contact_data .contact_dealer {
  border-top: 2px solid #ddd;
  padding-bottom: 0;
}

.contact_data .avatar {
  margin-right: 1.5rem;
}


.contact .text {
  margin-left: 1.5rem;
  font-weight: 400;
  color: #212121;
  padding: 1.5rem;
  background: #eee;
  border-radius: 0.6125rem;
  flex-grow: 1;
}

.contact_main_private +.contact_dealer {
  border-bottom: 0
}

.contact_main_private .contact_avatar img {
  width: 5rem;
  height: 5rem;
}



.contact_dealer_logo {
  flex-grow: 2;
  text-align: right
}

.contact_dealer_logo img {
  display: block;
  float: right;
}



/**********************/
/******* POPUP ********/
/**********************/


.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 20rem;
  background: #fff;
  min-height: 10rem;
  box-shadow: 0 0 2rem rgba(0,0,0,0.7);
  border-radius: 1rem;
  overflow: hidden;
  max-width: 90%;
  z-index: 960;
  user-select: none;
}

.popup_close {
  background: transparent;
  height: 3rem;
}

.popup_header {
  min-height: 3rem;
  background: #eee;
  display: flex;
}

.popup_content {
  padding: 0.75rem;
}

.header_popups {
  position: relative;
}


.popup_title {
  margin-top: 0.75rem;
  font-size: 1.25rem;
  padding-left: 0.75rem;
  margin-bottom: 0.75rem;
  margin-right: 3rem;
  flex-grow: 2;
}


.popup_video {
  width: 90vw;
  height: 90vh;
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
}

.popup_video iframe {
  width: 100%;
  height: 100%;
}

.popup_video .popup_content {
  height: 100%;
  padding: 0;
}

.popup_video .popup_content >div {
  height: 100%;
}



.popup_image, .popup_gallery {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  border-radius: 0;
  transform: none;
  overflow: hidden;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.7);
}

.popup_image .popup_header {
  background: $title;
  color: #fff;
}

.popup_image .popup_header  .popup_close {
  color: #fff;
}

.popup_image .popup_content {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;transform: translateY(-50%);
  padding: 0;
}

.popup_image .popup_content img {
  margin: 0 auto;
  max-width: 100vw;
  max-height: 90vh;
}



.popup_actions {
  display: flex;
  justify-content: center;
  margin: 0.75rem
}

.popup_content:empty {
  display: none;
}

.popup_actions button {
  background: $linkGray;
  color: #fff;
  height: 3rem;
  width: auto;
  padding: 0.5rem 1.5rem;
  border-radius: 10rem;
  margin: 0.75rem;
}


.popup_shadow {
  background: rgba(0,0,0,0.1);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 950;
}


/*.popup_content .progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2rem;
  height: 2rem;
  background: #f0f;
  z-index: -1;
}*/









/***********************/
/**** NOTIFICATIONS ****/
/***********************/


@keyframes notify {  0% { opacity: 0 }  100% { opacity: 1 }}

.notifications .notification {
  padding: 0.75rem;
  background: #eee;
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
  animation: notify 0.5s ease-in-out;
}

.notifications .notification .notification_message {
  flex-grow: 1;
}

.notifications .notification_notification {
  border-left: 0.5rem solid #59ab11;
}

.notifications .notification_warning {
  border-left: 0.5rem solid #f80;
}

.notifications .notification_error {
  border-left: 0.5rem solid #d00;
}

.notifications .notification {
  margin-bottom: 0.25rem;
}

.notification:last-child {
  margin-bottom: 0;
}








/**********************/
/******* SLIDER *******/
/**********************/

.slick-slide {
  position: relative;
}

.slide svg.playIcon,
.slide svg.playButton{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30%;
  max-width: 5rem;
  height: 30%;
  transform: translate(-50%, -50%);
  z-index: 10;
  cursor: pointer;
}



.slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:pointer}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}/*# sourceMappingURL=slick.min.css.map */


.slick-track {
  opacity: 1; transform: translate3d(0px, 0px, 0px);
}






/**********************/
/**** PARAGRAPHS ******/
/**********************/




.image_wrapper {
  display: flex;
  margin: 0 -0.75rem;
  flex-direction: row;
  align-items: center;
}

.image_wrapper.align_2r,
.image_wrapper.align_3r {
  flex-direction: row-reverse;
}

.image_wrapper > * {
  padding: 0 0.75rem;
}

.image_wrapper .text > :first-child{
  margin-top: 0;
}

.image_wrapper img {
  display: block;
}


.image_wrapper.align_2r .image,
.image_wrapper.align_2l .image{
  width: 50%;
}

.image_wrapper.align_2r .text_wrapper,
.image_wrapper.align_2l .text_wrapper{
  width: 50%
}

.image_wrapper.align_3r .image,
.image_wrapper.align_3l .image{
  width: 33.3%;
}

.image_wrapper.align_3r .text_wrapper,
.image_wrapper.align_3l .text_wrapper{
  width: 66.6%;
}


.paragraph_black {
  background: #212121;
  color: #fff;
}

// .paragraph_wide.paragraph_image {
//   overflow: hidden;
// }

.paragraph_wide.paragraph_image .image {
  position: relative;
}

.paragraph_wide.paragraph_image .align_2l img,
.paragraph_wide.paragraph_image .align_3l img{
  position: absolute;
  width: 50vw!important;
  right: 0;
  max-width: 120%;
  top: 50%;
  transform: translateY(-50%);
}

.paragraph_wide.paragraph_image .align_2r img,
.paragraph_wide.paragraph_image .align_3r img{
  position: absolute;
  width: 50vw!important;
  left: 0;
  max-width: 120%;
  top: 50%;
  transform: translateY(-50%);
}

.paragraph h2 {
  font-weight: 600;
  font-size: 2rem
}

.text_wrapper >h2:first-child {
  margin-top: 0;
}

.page {
  background-size: cover;
  margin-bottom: 3rem;
}

.page.page_page {
  margin-bottom: 0;
}

.text_wrapper {
  margin: 3rem 0;
}



.wide_container .image_wrapper .image {
  margin: 0 auto;
  padding: 0;
}


.paragraph {
  padding-bottom: 5rem;
  background-size: contain;
  background-repeat: repeat-x;
  background-position: center center;
}

/*.paragraph:first-of-type {
  padding-top: 0;
}*/






.paragraph .container > h2 {
  margin-top: 0;
  padding-top: 1.5rem;
}

.paragraph_news.paragraph_black .bs {
  box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.5);
  padding: 1.5rem;
  background: #fff;
}

.paragraph_news article {
  margin-bottom: 1.5rem;
  color: #000;
}

.paragraph_news article h3 {
  color: #000;
}

.paragraph_news .bs.card_wrapper {
  background: #fff;
}

/*.paragraph_news.paragraph_black a {
  color: #fff;
}

.paragraph_news.paragraph_black .readmore svg {
  stroke: #fff;
}*/

.paragraph_wave .wave svg {
  max-width: unset;
  width: 150rem;
  //height: 
}

.paragraph_wave .wave {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}

.paragraph_wave {
  margin-top: 18rem;
  position: relative;
  padding-bottom: 0;
  margin-bottom: -3rem;
}

.paragraph_wave .wave svg{
  display: block;
}

.paragraph_wave.paragraph_black .wave svg{
  fill: #212121;
  transform: translateY(1px);
}

.paragraph_wave.paragraph_white .wave svg{
  fill: #fff;
}


.paragraph_wave > .container {
  position: relative;
  top: -6rem;
}

// .align_2l, .align_3l, .align_25l, .align_35l p {
//   text-align: right;
// }

// .align_2l, .align_3l, .align_25l, .align_35l h2 {
//   text-align: right;
// }

.align_35l .lazy_image {
  max-width: 80%
}



.page_page .card h1 {
  font-size: 1.2rem;
  font-weight: 700;
  margin: 1.5rem 0;
  text-align: left;
}

.paragraph .text_wrapper h1 {
  font-weight: 600;
  font-size: 2rem;
  text-align: inherit;
}



.video_container h1,
.video_container h3 {
  text-align: inherit!important;
  margin-top: 0;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 2rem;
}






.paragraph {
  margin-bottom: 3rem;
  margin-top: 1.5rem;
}

.paragraph_wide.paragraph {
  margin-top: 6rem;
  padding-top: 3rem;
}

.paragraph.paragraph_wave {
  margin-top: 16rem;
}


.paragraph:last-child {
  margin-bottom: 0;
}


@media all and (max-width: 1200px) {
  .paragraph {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .paragraph_wide.paragraph {
    margin-top: 2rem;
    padding-top: 2rem;
  }

  .paragraph.paragraph_wave {
    margin-top: 16rem;
  }


  .paragraph:last-child {
    margin-bottom: 0;
}
}


@media all and (max-width: 800px) {
  .paragraph {
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
  }

  .paragraph_wide.paragraph {
    margin-top: 0;
    padding-top: 0;
  }

  .paragraph.paragraph_wave {
    margin-top: 12rem;
  }


  .paragraph:last-child {
    margin-bottom: 0;
  }
}








/**********************/
/******* TOGGLE *******/
/**********************/



.form-toggle fieldset {
  border: 1px solid #ddd;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.form-toggle input {
  display: none;
}

.form-toggle  label {
  display: block;
  width: 100%;
  height: 100%;
  line-height: 2.5rem;
  cursor: pointer;
}

.form-toggle input:checked + label {
  background: #888;
  color: #fff;
  font-weight: bold;
  
}

.form-toggle fieldset .toggle-child{
  flex-grow: 1;
  text-align: center;
}

.toggle-child {
  user-select: none;
}



.form-toggle fieldset {
  border: 0;
  margin-bottom: 1rem;
}

.form-toggle input:checked + label {
  background: #fff;
  color: #000;
  font-weight: 500;
  border-bottom: 2px solid #555;
}



/**********************/
/*** PAGE OVERRIDES ***/
/**********************/




.page__airone {

  //max-width: 1200px;
  //margin: 0 auto;

  h1 {
    text-align: center;
  }

  #paragraph_3{
    padding-bottom: 0
  }
}



.page_construction {
  background-size: cover;
  margin: 0;
  background-position: center center;

  .floater {
    color: #fff;
    font-size: 3rem;
    text-align: center;
  }
}



.page_404 {
  margin: 0;
  background-size: cover;
  background-position: center center;

  .big404 {
    font-size: 6.75rem;
    color: #212121;
  }

  .small404 {
    font-size: 1rem;
    color: #212121;
  }
}


.page_airone {
  font-size: 1.25rem;
}

.page_airone #paragraph_1 {
  background: linear-gradient(rgba(255,255,255,0), rgba(255,255,255,255) 20%, rgba(255,255,255,255) 80%, rgba(255,255,255,0))
}




.page_chat,
.page_showroom {
  margin-bottom: 0
}

/*
.page_showroom__showroom-helicopters #activezone img {
  margin-top: -6rem;
}

.page_showroom__showroom-helicopters #showroom_logos {
  margin-top: 0;
}

.page_showroom__showroom-helicopters #activezone video {
  top: -6rem;
  height: calc(100% + 6rem);
  position: absolute;
  transform: scale(1.01);
  max-width: 100%;
}*/

.page_chat + footer, .page_rl +footer {
  display: none;
}




.chats_list_title h1, .requestbox_title h1 {
  position: relative;
}

.chats_list_title h1::after, .requestbox_title h1::after {
  position: absolute;
  content: "beta";
  top: 1.25rem;
  line-height: 1rem;
  font-size: 14px;
  font-weight: 400;
  color: #444;
  left: 100%;
  transform: translateX(0.25rem);
}











/**********************/
/******* MOBILE *******/
/**********************/

@media all and (min-width: 3700px) {
  .paragraph_wave {
    margin-top: 30rem;
    padding-bottom: 30rem;
  }

}


@media all and (max-width: 1100px) {
  .hide-l {
    display: none;
  }
  .c4 {
    width: 33%!important;
  }
  #showroom_wrapper h1 {
    font-size: 1.75rem;
    /*top: 12%;*/
  }
  #form_searchListing .form-element.form-text {
    width: 100%;
  }
  .c3 {
    width: 50%;
  }
  .bar .image-box {
    width: 25vw;
    margin-right: 0.75rem;
  }
  #menu_profile {
    right: 0;
  }

  .header_actions {
    min-width: unset;
    text-align: right;
  }

  .header_actions button.gray{
    padding: 0;
    background: transparent;
    text-align: center;
    padding: 0;
    margin: 0;
    margin-left: 0.5rem;
  }

  .header_actions button.gray span {
    display: none;
  }

  .header_actions button.gray svg {
    width: 1.75rem;
    height: 1.5rem;
  }

  .tabs_navigation .tab {
    width: 33.3%;
  }

  #listing_main .tabs  .tabs_navigation {
    flex-wrap: wrap;
    position: relative;
    transform: none;
  }


  ul.features {
    column-count: 2;
  }

  #listing_main {
    margin-top: 2.5rem;
  }

  .showroom_contacts > .showroom_contact {
    display: block;
    border-bottom: 2px solid #eee;
    margin-bottom: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .showroom_contacts > .showroom_contact >div {
    display: block;
    border-bottom: 0;
  }
}


@media all and (max-width: 900px) {
  .hide-m {
    display: none;
  }
  .m4 > div{
    width: 50% !important;
  }
  .c2 > .row > .column {
    width: 100%!important;
  }
  .shawarma {
    width: 100%;
    opacity: 0.35;
    top: 10rem;
  }
  #showroom_wrapper h1 {
    font-size: 1.6rem;
    /*top: 11%;*/
  }
  .gallery_dots div.slide img {
    max-width: 1.5rem;
    padding: 0;
  }
  .slick-slider.gallery_dots.bs {
  box-shadow: none;
    padding: 1rem 2rem;
    margin-top: 0;
  }

  .gallery_dots .gallery_arrow.arrow_prev {
    right: 0;
  }

  .gallery_dots .gallery_arrow.arrow_next {
    left: 0;
  }

  .header_actions {
    white-space: nowrap;
    margin-left: 0.75rem;
    min-width: 5.5rem;
  }


  .header_actions button{
    margin: 0;
  }

  .header_actions button svg {
    height: 1.25rem!important;
    padding: 0;
    margin: 0;
  }

  

  .listing h1 {
    font-size: 1.3rem;
    line-height: 1.2;
  }



  button.black {
    font-size: 1.1rem;
    padding: 0rem 1rem;
    height: auto;
    line-height: 2;
  }



  .paragraph_card .card {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .paragraph_card .cards {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .paragraph .call_to_action a.link {
    max-width: 100%;
    font-size: 1.5rem;
  }

  .page_airone .image_wrapper.align_2r .text_wrapper {
    width: 45%;
  }

  .page_airone .image_wrapper.align_2r .image {
    width: 55%;
  }


  page_airone  .image_wrapper.align_2l .text_wrapper {
    width: 55%;
  }

  page_airone  .image_wrapper.align_2l .image {
    width: 45%;
  }


  .page_airone  .image_wrapper.align_2r .image {
    width: 55%;
  }

  .page_airone  .image_wrapper.align_2l .image {
    width: 45%;
  }




  .dealer_block_form {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .dealer_block_form >div {
    box-sizing: border-box;
  }

  .dealer_block_form .dc {
    width: 100%;
    padding: 0;
  }

  .dealer_block_form .lc {
    flex-grow: 1;
    padding-left: 1.5rem;
  }

  .dealer_block_form .mc {
    width: 15rem;
  }

  .paragraph {
    padding-bottom: 1.5rem;
    
  }

  .dealers_list {
    grid-template-columns: 1fr 1fr 1fr;
  }

}



@media all and (max-width: 800px) {
  #logo {
    height: 2.5rem;
  }

  #content_wrapper {
    padding-top: 2.7rem;
  }

  .chat_unread {
    line-height: 0.9rem;
  }

  .own_listing {
    display: none;
  }

  .own_listing_mobile {
    display: block;
  }


  .header_buttons button {
    height: 2.5rem;
  }

  .c4 {
    width: 50%!important;
  }
  .header_buttons span{
    display: none;
  }
  .header_buttons button {
    width: 3rem;
    padding-top: 0;
  }

  

  .header_buttons svg {
    width: 3rem;
    height: 1.25rem
  }
  #showroom_wrapper h1 {
    font-size: 1.5rem;
    /*top: 10%;*/
  }
  .c3 {
    width: 80%;
    margin: 0 auto;
  }
  .bar .image-box {
    width: 30vw;
    margin-right: 0.75rem;
  }
  #menu_profile {
    width: 100%;
    height: calc(100vh - 3.25rem);
  }
  
  #menu_profile #form_login {
    max-width: 20rem;
  }

  #btn_user {
    margin-right: -0.625rem
  }


  .columns-2 {
    flex-wrap: wrap;
  }

  .columns-2 >div {
    width: 100%;
  }


  .profile_dealer_view {
    padding-left: 0;
    padding-right: 0;
  }
  .profile_dealer_view .billing_plan{
    margin: 0.75rem
  }

  .profile_dealer_view >.subdealer_profile {
    flex-wrap: wrap;
    justify-content: center;
  }

  .profile_dealer_view .dealer_data {
    width: auto;
    padding: 0;
    flex-grow: 0;
    align-items: center;
  }

  .contact_main .contact {
    flex-wrap: wrap;
  }

  .contact_main .text {
    margin: 0;
    margin-top: 0.75rem;
  }

  .subdealers {
    justify-content: center;
  }



  .contact_contacts {
    display: block!important;
  }

  .contact_asocials {
    display: block!important;
  }

  .contact_asocials a {
    padding-left: 0!important;
  }


  #btn_plus_listing, #btn_plus_request {
    height: 3.5rem;
    width: 3.5rem;
  }

  #btn_plus_request span,
  #btn_plus_listing span {
    display: inline;
  }

  #addFloater {
    left: 3rem;
  }

  #addFloater.down #btn_plus_listing {
    /*top: 2.5rem;
    left: -2rem;*/
    transform: translate(-3.5rem, 2.5rem);
  }

  #addFloater.down #btn_plus_request {
    /*top: 2.5rem;
    left: 1.5rem;*/
    transform: translate(2rem, 2.5rem)
  }


  .profile_actions button.black {
    margin-bottom: 0.75rem;
  }


  .subdealer_requests {
    display: block;
    width: 100%;
  }

  .subdealer_requests .subdealer_request{
    display: block;
    width: 100%;
    margin-bottom: 0.75rem;
  }

  .subdealer_requests .subdealer_request >div {
    display: block;
    border: none;
  }

  .request_created {
    float: right;
    line-height: 2.25rem;
    
  }




  html {
    height: 100%;
    max-height: 100%;
  }

  body{
    height: 100%;
  }

  /*#content_wrapper {
    height: calc(100% - 2px);
    box-sizing: border-box;
  }*/


  .page_rl, .page_chat {
    height: 100%;
  }

  

  footer {
    min-height: auto;
  }

  #root {
    height: 100%;
  }

  .paragraph_wave .wave svg{
    width: 110rem;
  }

  .ai.closed {
    right: calc(100% - 5.125rem);

  }

} 



@media all and (max-width: 700px) {
  
  .hide-sm {
    display: none!important;
  }

  .only-sm {
    display: inherit!important;
  }

  .empty_compare_wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  .empty_compare_wrapper .empty_compare_text {
    flex-grow: 1;
    width: 100%;
    text-align: center;
    margin-bottom: 1.5rem;
  }  

  #showroom_wrapper h1 {
    font-size: 1.5rem;
    /*top: 9%;*/
  }

  .header_line h1 {
    font-size: 1.5rem;
  }

  .paragraph {
    padding-bottom: 0.75rem;
  }

  .paragraph h2 {
    font-size: 1.4rem;
  }

  .c3 {
    width: 100%;
  }
  .bar .image-box {
    width: 30vw;
    margin-right: 0.75rem;
  }

  .tabs_navigation .tab {
    width: 50%;
  }

  #listing_main .tabs  .tabs_navigation {
    flex-wrap: wrap;
    position: relative;
    transform: none;
  }

  .tabs_tab .prop {
    width: 100%;
  }

  ul.features {
    column-count: 1;
  }

  .header_line,
  .header_line.container {
    /*margin-top: 3rem;*/
    position: relative;
  }

  .header_line .button_back span {
     display: none;
  }

  .header_line .button_back {
    margin: 0;
  }

  .profile_block_container {
    flex-direction: column;
    
  }

  .profile_block_container h2{
    text-align: left;
  }
  
  .profile_block_container > .profile_block_main {
    width: 100%;
    padding: 0 0.75rem;
    box-sizing: border-box;
  }

  .profile_avatar {
    width: 12rem;
    height: 12rem;
    margin-left: auto;
    margin-right: auto;
  }

  .profile_block_form_main {
    flex-direction: column;
  }

  .profile_block_form_main > div.mc {
    padding: 0;
  }

  .profile_block_container > .profile_block_social {
    width: 100%;
    padding: 0 0.75rem;
    box-sizing: border-box;
    margin-top: 1.5rem;
  }


  .inventory_card {
    flex-wrap: wrap;
  }

  .inventory_data {
    width: calc(100% - 9rem)
  }

  .inventory_state {
    padding: 0;
    padding-right: 0.5rem;
    font-size: 14px;
  }

  .inventory_title h3 {
    margin-bottom: 0.5rem;
  }

  .inventory_actions button span{
    font-size: 12px;
  }

  .inventory_actions button {
    width: 3.5rem
  }


  #form_createListing .main .field_box > div.form-element, 
  #form_createListing main form .form-element {
    width: 100%!important;
  }

  .field_group {
    margin-bottom: 1.5rem;
  }

  #form_createListing {
    display: flex;
  }

  .form-toggle {
    height: 100%;
    position: relative;
  }

  .form-toggle fieldset {
    flex-direction: column;
    margin-left: -0.75rem;
    margin-right: 1rem;
  }

  .form-toggle fieldset {
    flex-wrap: wrap;
    border: none;
  }

  .form-toggle fieldset .toggle-child label{
    background: #eee;
    font-size: 14px;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }


  .form-toggle fieldset .toggle-child input:checked + label {
    background: #666!important;
    border-bottom: 0;
    color: #fff;
  }


  #form_createListing form {
  display: flex;
  flex-wrap: wrap;

    .field_group.fms,
    .field_group.autopilot,
    .field_group.transponder,
    .field_group.vhf_com,
    .field_group.vhf_nav,
    .field_group.gps{
      width: 100%;
    }
  }

  table#compare td {
    min-width: 8rem;
    padding: 0.25rem
  }
  table#compare .proprow_Title td {
    font-size: 1rem;
  }

  .compare_small {
    max-width: 7rem
  }


  /*showroom new*/


  #showroom_links {
    display: flex;
    flex-wrap: wrap;
  }

  .showroom_link {
    width: 50%;
    //padding: 1.5rem 1rem;
    box-sizing: border-box;
    text-align: center;
    z-index: 10;
  }

  #related_showrooms > div{
    font-size: 1.125rem;
    padding: 0 0.5rem;
  }

  #showroom_video {
    z-index: 1;
  }

  .playing.page_showroom #showroom_video {
    z-index: 100;
  }

  .showroom_video_wrapper {
    opacity: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    transition: opacity 0.7s ease-in-out;
  }

  .showroom_video_wrapper.playing {
    
    opacity: 1;
  }

  .showroom_video_clipbox {
    top: 50%;
    transform: translateY(-50%) scale(1.5);
  }

  #showroom_video {
    height: 100%;
  }


  #showroom_links > a {
    flex-grow: unset;
  }

  main .field_box > div.form-element, main form .form-element {
    width: 100%;
  }
  .paragraph_wave .wave svg{
    width: 90rem;
  }

  .paragraph_wide.paragraph_image .align_2l img, .paragraph_wide.paragraph_image .align_3l img,
  .paragraph_wide.paragraph_image .align_2r img, .paragraph_wide.paragraph_image .align_3r img{
    width: 100%!important;
    position: static;
    transform: none;
  }

  .paragraph_wide.paragraph_image .align_2l img {
    margin-top: 4rem;
  }

  .image_wrapper.align_2r .image, .image_wrapper.align_2l .image,
  .image_wrapper.align_3r .image {
    width: 100%
  }

  .image_wrapper.align_35l .image {
    width: 60%;
  }

  .text_wrapper {
    text-align: center;
  }

  .align_35l .lazy_image {
    max-width: 100%;
  }

  .paragraph_wave > .container {
    top: -3rem;
  }

  .popup_gallery, .popup_shadow {
    display: none;
  }

  .playIcon {
    height: 1.5rem;
    width: 2rem;
  }

  .dealers_list {
    grid-template-columns: 1fr 1fr;
  }


  .ai {
    right: 0;
    width: 95vw;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }


  .ai__chat.opened {
    right: 2.5vw;
    top: 0;
    //height: calc(100vh - 3em);
    width: 90vw;
    box-sizing: border-box;
    /*border: none;
    padding: 0.75rem 0;
    box-shadow: none;*/
    display: flex;
    flex-direction: column;
  }

  .ai__chat--messages {
    /*flex-grow: 2;*/
  }

  .input_row_box_wrapper {
    flex-grow: 1;
  }

  .ai__chat--header {
    padding-left: 3.5rem;
  }

  .ai__chat--header svg{
    width: 2.5rem;
    height: 2.5rem;
  }

  .ai__e {
    z-index: 10;
    left: 5vw;
    top: 0.25rem;
    position: absolute;
    width: 3rem;
    height: 3rem;
  }



}

@media all and (max-width: 700px) and (orientation: portrait) {
  .mob {
    display: flex;
    flex-wrap: wrap;
  }

  ul.tabnav li span {
    padding: 0 0.75rem;
  }

  .advanced_search {
    display: none;
  }

  .pc {
    display: none;
  }

  /*table#compare tr > td {
    max-width: 8rem;
    min-width: 8rem
  }*/

  #showroom_wrapper h1 {
    margin-bottom: 0.75rem;
  }


  /*#related_showrooms {
    position: static;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 0.75rem 1rem 0 1rem;
    font-size: 1.5rem;
    border-top: 1px solid #aaa;
    padding: 0.75rem 0 0 0;
  }*/

  #menu_profile ul {
    font-size: 1.25rem;
    text-align: center;
  }

  #menu_profile ul li a {
    width: 100%;
    padding: 0.25rem;
    box-sizing: border-box;
    display: block;
  }


  .page_airone h1 {
    font-size: 1.7rem;
    padding-left: 1.5rem;
    font-weight: 600!important;
    padding-right: 1.5rem;
    text-align: center;
    margin-top: 1.5rem;
  }

  .paragraph_card .cards {
    margin: 0;
    flex-direction: column;
  }

  .paragraph_card .cards .card {
    width: 100%;
    margin-bottom: 3rem;
    padding: 0;
  }

  .paragraph_card .cards .card .url {
    position: static;
  }

  .paragraph_image .image_wrapper.align_2r, .image_wrapper.align_3r{
    flex-direction: column;
  }

  .paragraph_image .image_wrapper.align_2r .image {
    width: auto;
  }

  .paragraph_image mage_wrapper.align_2r .text_wrapper {
    width: auto;
  }
  .image_wrapper.align_2r .text_wrapper, .image_wrapper.align_2l .text_wrapper {
   width: auto;
  }

  .image_wrapper {
    flex-direction: column;
  }

  .image_wrapper.align_3r .image, .image_wrapper.align_3l .image {
    width: auto;
  }

  .image_wrapper.align_3r .text_wrapper, .image_wrapper.align_3l .text_wrapper{
    width: auto;
  }


  .page_airone .image_wrapper.align_2r .text_wrapper {
    width: auto;
  }

  .page_airone .image_wrapper.align_2l .image {
    width: auto;
  }


  .page_airone #paragraph_3 .image_wrapper {
    flex-direction: column-reverse;
  }
  .paragraph_wave .wave svg{
    width: 80rem;
  }



}


  .only-sm {
    display: none;
  }


@media all and (max-width: 580px) {
  .c4 {
    width: 100%!important;
  }
  /*.m4 > div {
    width: 100%!important;
  }*/

  .seller .seller_image {
    width: 3rem;
  }


  #showroom_wrapper h1 {
    margin-bottom: 0.75rem;
  }

  .filters.m4 > div.toggler {
    width: 100%!important;
    text-align: center;
    display: block;
  }

  .filters.m4 > div.toggler button {
    margin: 0 auto;
    width: 15rem;
    font-weight: 500;
    border-bottom: 1px solid #000;
    margin-bottom: 1.5rem;
  }



  .filters.fh .range_select {
    display: none;
  }

  .filters.m4.fh > div.toggler button {
    border: none;
    margin-bottom: 0;
  }

  .range_select_inputs .toggler {
    display: block;
  }

  .range_select_inputs input {
    display: none;
  }

  .range_select_inputs .label {
    display: inline-block;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  .hide-s {
    display: none!important;
  }

  

  #showroom_wrapper h1 {
    font-size: 1.5rem;
    /*top: 9%;*/
  }
  .bar .image-box {
    width: 35vw;
    margin-right: 0.75rem;
  }
  #form_searchListing .form-element.form-select {
    width: 100%
  }


  .news_image_container{
    max-width: 100%;
    margin: 0;
    width: auto;
  }


  .page_airone h1 {
    font-size: 1.7rem;
    padding-left: 1.5rem;
    font-weight: 600!important;
    padding-right: 1.5rem;
    text-align: center;
    margin-top: 1.5rem;
  }

  .paragraph_card .cards {
    margin: 0;
    flex-direction: column;
  }

  .paragraph_card .cards .card {
    width: 100%;
    margin-bottom: 3rem;
    padding: 0;
  }

  .paragraph_card .cards .card .url {
    position: static;
  }

  .paragraph_image .image_wrapper.align_2r, .image_wrapper.align_3r{
    flex-direction: column;
  }

  // .paragraph_image .image_wrapper.align_2r .image {
  //   width: 50%;
  // }

  .paragraph_image mage_wrapper.align_2r .text_wrapper {
    width: auto;
  }
  .image_wrapper.align_2r .text_wrapper, .image_wrapper.align_2l .text_wrapper {
   width: auto;
  }

  .image_wrapper {
    flex-direction: column;
  }

  .image_wrapper.align_3r .image, .image_wrapper.align_3l .image {
    width: auto;
  }

  .image_wrapper.align_3r .text_wrapper, .image_wrapper.align_3l .text_wrapper{
    width: auto;
  }


  .page_airone .image_wrapper.align_2r .text_wrapper {
    width: auto;
  }

  .page_airone .image_wrapper.align_2l .image {
    width: auto;
  }


  .page_airone #paragraph_3 .image_wrapper {
    flex-direction: column-reverse;
  }


  .card_wrapper, .news_wrapper {
    flex-direction: column;
  }

  .bar .image-box {
    width: 100%;
    margin-bottom: 1.5rem;
    max-width: 100%
  }


  .dealer_block_form {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
  }

  .dealer_block_form .dc, .dealer_block_form .lc,
  .dealer_block_form .mc{
      box-sizing: border-box;
      width: 100%;
      flex-grow: 1;
      padding: 0;
  }

  .form-image-single {
    margin: 0 auto;
  }

  .paragraph_wave > .container {
    top: 0rem;
  }
  

  .paragraph_wave .wave svg{
    width: 60rem;
  }

  .image_wrapper.align_35l .image {
    width: 70%;
  }

  .paragraph_wave {
    margin-top: 10rem;
  }
  

  .paragraph_wide.paragraph_image .align_2l img, .paragraph_wide.paragraph_image .align_3l img,
  .paragraph_wide.paragraph_image .align_2r img, .paragraph_wide.paragraph_image .align_3r img{
    width: 100%!important;
    position: static;
    transform: none;
  }

  .paragraph_wide.paragraph_image .align_2l img {
    margin-top: 4rem;
  }

  .image_wrapper.align_2r .image, .image_wrapper.align_2l .image,
  .image_wrapper.align_3r .image {
    width: 100%
  }

  .text_wrapper {
    text-align: center;
  }


  .showroom.full #listing_contact .buttons {
    display: block;
  }

  .showroom.full #listing_contact .buttons .a1Button {
    margin-bottom: 0.75rem;
  }


}




/**********************/
/******* PRINT ********/
/**********************/


@media print {

    html {
      overflow-y: auto;
      height: auto!important;
      max-height: 100000px!important;
      
    }

    body {
      overflow-y: visible!important;
      height: auto!important;
      max-height: 100000px!important;
    }

    #root {
      height: auto!important;
      max-height: 100000px!important;
      overflow-y: visible!important;
    }


    #content_wrapper {
      display: block!important;
      height: 100%;
    }


    .print_only {
      display: block;
    }

    header#header nav{
      display: none!important;
    }

    header#header {
      box-shadow: none;
      position: static;
    }

    #content_wrapper {
      margin-top: 0;
    }

    .bs {
      box-shadow: none!important;
    }

    .header_actions button{
      display: none;
    }

    .slick-slider {
      padding: 0
    }

    .gallery_arrow {
      display: none;
    }


    #listing_contact button{
      display: none;
    }

    footer {
      display: none;
    }

    #listing_main {
      padding: 0
    }

    .gallery_dots {
      display: none
    }

    .gallery_slides.slick-slider {
      display: block!important;
    }

    .gallery_slides .slick-track {
      display: inline!important;
    }

    .gallery_slides .slick-track .slick-slide {
      display: none
    }

    .gallery_slides .slick-track .slick-slide:first-child {
      display: block!important;
      max-width: 90%;
    }

    .gallery_dots.slick-slider {
      display: block!important;
      padding: 0!important;
    }

    .gallery_dots .slick-track {
      display: inline!important;
    }

    .gallery_dots .slick-track .slide {
      display: inline-block!important;
    }

    .gallery_dots div.slide img {
      max-width: 7rem;
    }

    .gallery_dots .slick-slide:first-child {
      display: none!important;
    }

    .gallery_dots .slick-list {
      padding: 0!important;
    }

    .own_listing ,
    .own_listing_mobile,
    .header_line .back,
    .tabs_navigation
    {
      display: none!important;
    }

    .tabs_tab {
      display: flex!important; 
      margin-top: 1.5rem;
    }

    .tabs_tab .prop {
      width: 50%!important;
    }

    ul.features {
      column-count: 4!important;
    }

    .file_download {
      display: none!important;
    }

    .tabs_tab {
      page-break-inside: avoid;
      page-break-before: avoid;
    }

    h2 {
      page-break-after: avoid;
    }


}














